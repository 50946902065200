.test {
  opacity: 0;
}

.upload-fix-1 {
  transform: translateY(-10px);
}

.dob-fild-fix .has-icon input {
  padding-right: 0;
}

.th-right-wrap-sec.text-center td {
  text-align: center;
}

.th-right-wrap-sec.text-center td .th-table-dtls h4 {
  width: 100%;
}

@media only screen and (max-width: 1799px) {
  .common-card-style {
    padding: 30px;
  }
}

@media only screen and (max-width: 1599px) {
  .common-card-style {
    padding: 25px;
  }

  .mb-40 {
    margin-bottom: 30px;
  }

  .job-details-sidebar {
    padding: 25px;
  }

  .details-is-open .common-card-style {
    width: calc(50% - 10px);
  }

  .details-is-open .job-details-sidebar {
    display: block;
    width: calc(50% - 10px);
  }

  .cus-tab button.MuiTab-root {
    font-size: 16px;
    margin-right: 40px;
  }

  .comp-info ul {
    max-width: 500px;
  }

  .payment-dtls-right {
    padding: 16px;
    border-radius: 15px;
  }

  .job-key-info-wrap ul li {
    width: auto;
    max-width: 25%;
  }
}

/* @media only screen and (max-width:1359px) {
    .job-action-btn-full button {
        display: block;
    }

    .job-action-btn-full button:last-child {
        margin-left: 0 !important;
        margin-top: 10px !important;
    }
} */

@media only screen and (max-width: 1279px) {
  .job-infobox-single {
    padding: 15px;
  }

  .job-key-info-wrap ul {
    flex-wrap: wrap;
  }

  .job-key-info-wrap ul li {
    /* flex: 0 0 calc(50% - 15px); */
    margin-bottom: 10px;
    /* width: calc(25% - 15px); */
  }

  .job-icon {
    margin-right: 10px;
  }

  .job-icon img {
    max-width: 50px;
    height: auto;
  }

  .job-infobox-header {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px 15px 15px;
  }

  .job-action-icons button {
    width: 40px;
    height: 40px;
  }

  .job-short-desc {
    padding-top: 5px;
  }

  .job-action-icons button:first-child {
    margin-right: 5px;
  }

  .job-action-btn-full {
    display: flex;
  }

  .job-list-info ul {
    padding-right: 135px;
  }

  .job-list-info ul span {
    flex: 0 0 135px;
  }

  .job-list-info ul div {
    flex-basis: calc(100% - 135px);
  }

  :is(.usages-section, header, footer) .container {
    max-width: 95% !important;
  }

  .users-tab-new .MuiTabs-flexContainer {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .users-section .MuiTabs-root {
    background-color: transparent !important;
  }

  .users-tab-new .MuiButtonBase-root {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1199px) {
  .contact-section.common-padding {
    padding-bottom: 485px;
  }
}

@media only screen and (max-width: 991px) {
  .common-card-style {
    padding: 15px;
    border-radius: var(--radius-20);
  }

  .job-details-sidebar {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
    transition: 300ms ease-in-out;
    transform: translateY(100%);
  }

  .details-is-open {
    display: block;
  }

  .job-details-sidebar {
    top: 0;
  }

  .details-is-open .common-card-style {
    width: 100%;
  }

  .details-is-open .job-details-sidebar {
    transform: translateY(0%);
    width: 100%;
  }

  .prof-com-info-row {
    flex-direction: column;
  }

  .prof-act-btn-2 {
    padding-top: 20px;
  }

  .invite-dtls-right {
    margin-left: 0;
  }

  .invite-avatar {
    padding-bottom: 90%;
  }

  /*------ Responsive Fix ------*/
  .files-sec-holder h5 {
    font-size: 18px !important;
  }

  .files-sec-holder h6 {
    margin-bottom: 20px;
  }

  .files-blocks-holder {
    margin-bottom: 30px;
  }

  .contact-section.common-padding {
    padding-bottom: 20px;
  }

  img.contect-bg {
    position: static;
    height: auto;
  }

  .acc-sec-from {
    text-align: center;
  }

  .acc-sec-from .form-group {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .acc-sec-from .btn-wraps {
    justify-content: center;
  }

  .user-menu > span {
    display: none;
  }

  .dash-top-nav h1 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .form-btn-holder button {
    margin-bottom: 15px;
    margin-left: 0 !important;
    display: block;
  }

  .addi-icon-wrap {
    flex: 0 0 70px;
    margin-left: 10px;
  }

  .cus-tab button.MuiTab-root {
    font-size: 14px;
    margin-right: 20px;
  }

  .job-action-icons button svg {
    width: 20px;
    height: 20px;
  }

  .job-infobox-header {
    flex-wrap: wrap;
  }

  .job-icon {
    flex: 0 0 50%;
    margin-right: 0;
  }

  .job-action-icons {
    flex: 0 0 100px;
    text-align: right;
  }

  .job-title-wrap {
    order: 2;
    flex: 0 0 100%;
    margin-top: 5px;
  }

  .cus-tab .MuiTabPanel-root {
    padding: 20px 0 0 0 !important;
  }

  .job-provider-info {
    margin-left: 0;
    text-align: left;
    order: 3;
    margin-top: 10px;
  }

  .job-key-info-wrap ul li {
    flex-basis: 100%;
    display: flex;
  }

  .job-key-info-wrap ul li > span {
    padding-right: 10px;
  }

  .job-list-info ul li.salary-li {
    position: static;
  }

  .job-list-info ul {
    padding-right: 0;
    font-size: 14px;
  }

  .tech-stack ul {
    flex-wrap: wrap;
  }

  .tech-stack ul li {
    margin-right: 10px;
  }

  .tech-stack ul li img {
    width: 50px;
    height: auto;
  }

  .res-img-holder img {
    min-height: 110px;
  }

  .user-pic img {
    width: 80px;
    height: 80px;
  }

  .profile-sec-header {
    margin-top: -50px;
  }

  .profile-sec-header {
    flex-direction: column;
  }

  .prof-btn-holder {
    padding-top: 20px;
  }

  .comp-info ul {
    flex-direction: column;
  }

  .prof-btn-holder button:last-child {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .user-info-holder {
    margin-top: 10px;
  }

  .prof-act-btn-2 button:last-child {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .tech-stack.style-2 li {
    margin-bottom: 10px;
  }

  .files-sec-holder > .row > div:last-child {
    margin-top: 20px;
  }

  .profile-tab-cus .MuiTabs-scroller > .MuiTabs-flexContainer {
    overflow: auto;
  }

  .about-post-holder {
    flex-direction: column;
  }

  .post-image-ab {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-post-holder {
    padding-right: 20px;
  }

  .post-image-ab {
    margin-right: 0;
  }

  .about-post-holder {
    padding-left: 0;
    padding-right: 0px;
  }

  .about-post-holder .feed-post-interaction .feed-post-colum {
    margin-right: 10px;
  }

  .about-post-holder .feed-post-menu button.MuiButton-text {
    color: #fff;
    margin-top: 10px !important;
  }

  .prof-btn-holder,
  .prof-act-btn-2 {
    display: flex;
    flex-direction: column;
  }

  .prof-act-btn-2 button,
  .prof-btn-holder button {
    min-width: 100% !important;
  }

  .invite-avatar-list {
    max-width: 200px;
  }

  .invite-dtls-right {
    padding-left: 0;
    margin-top: 20px;
    border: 0;
  }

  .common-modal-style.invite-modal-outer {
    padding-top: 40px !important;
    overflow: auto;
  }

  /*.common-modal-style .common-modal-box {
        top: 40px;
        transform: translate(-50%, 0px);
    }*/

  .invite-modal-outer .modal-cross {
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background-color: var(--primary);
    border-radius: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .write-something-wrap input {
    font-size: 16px;
  }

  .share-profile-modal .common-modal-box {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .files-sec-holder h6 {
    margin-bottom: 0px;
  }

  .users-tab-new {
    text-align: center;
  }

  .users-text p {
    max-width: 100%;
  }

  .users-section .MuiTabs-root {
    margin-left: auto;
    margin-right: auto;
  }

  .users-section .register-btn {
    margin-left: auto !important;
    margin-right: auto;
    display: block;
  }

  .ban-policy {
    text-align: center;
  }

  .usages-Chip .MuiChip-filled {
    font-weight: 600;
    font-size: 12px;
  }

  .usages-Chip .MuiChip-filled .MuiChip-label {
    white-space: pre-wrap;
  }

  .contact-section.common-padding {
    padding-top: 0;
  }

  .job-action-btn-full {
    flex-direction: column;
  }

  .job-infobox-wrap .job-action-btn-full {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .job-action-btn-full button:last-of-type {
    /*margin-top: 10px;*/
    margin-left: 0 !important;
  }

  .job-key-info-wrap ul {
    flex-direction: column;
    row-gap: 5px;
  }

  .MuiBox-root .mb-30 {
    margin-bottom: 0;
  }

  .job-key-info-wrap ul li {
    width: 100%;
    max-width: 100%;
  }

  .job-details-sidebar .job-list-info ul li {
    flex-direction: column;
  }

  .job-details-sidebar .job-list-info ul span {
    flex: 0 0 auto;
  }

  .job-details-sidebar .job-list-info ul div {
    flex: 0 0 auto;
  }

  .job-list-info ul .list-work-wrap span.list-work {
    flex: 0 0 auto;
    padding-left: 10px;
  }

  .job-list-info ul .list-work-wrap span.list-work:first-child {
    padding-left: 0;
  }

  .job-details-sidebar .job-list-info ul li.salary-li {
    display: flex;
  }

  .job-list-info ul li.salary-li div {
    padding: 0;
  }

  .job-form-wrap .form-list {
    margin-bottom: 20px;
  }

  .job-form-btns {
    margin-top: 20px;
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .job-form-btns button {
    width: 100% !important;
  }
  .job-listing-item .job-listing-item-img {
    display: flex;
    align-items: center;
  }

  .job-listing-item .job-listing-item-img .MuiAvatar-root {
    width: 40px;
    height: 40px;
  }

  .job-listing-item label {
    margin-right: 5px;
  }
  .rev-cand-row .job-listing-hidden .job-listing-info ul {
    max-width: 100%;
    margin-left: 0;
    padding-top: 10px;
    row-gap: 10px;
  }
  .rev-cand-row .job-listing-hidden .job-listing-info ul li {
    width: 100%;
    flex-basis: 100%;
    padding-bottom: 8px;
    margin-bottom: 9px;
    border-bottom: 1px solid #eaeaea;
  }
  .job-form-btns {
    margin-top: 15px !important;
  }
  .rev-cand-row ul.job-tech-stack li {
    width: 31.3%;
    background: #eaeaea;
    padding: 9px 5px;
  }

  .rev-cand-row ul.job-tech-stack li p {
    font-size: 10px !important;
    margin-top: 7px;
  }
  .job-publish-btn button {
    width: 100% !important;
  }
  .job-listing-icons {
    margin: 15px 0;
  }
}
