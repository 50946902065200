@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  /* Light Mode */
  --bg--light: red;

  /* Dark Mode */
  --bg--dark: green;
}

:root[color-scheme="dark"] {
  --bg: var(--bg--dark);
  color-scheme: dark;
}

:root[color-scheme="light"] {
  --bg: var(--bg--light);
  color-scheme: light;
}

:root {
  --main-font: "Inter", sans-serif;
  --primary: #031729;
  --dashboard-blue: #002d55;
  --secondary: #1a8ac9;
  --black: #000000;
  --white: #ffffff;
  --red: #c50000;
  --red-n: #ff0000;
  --sub-menu: #0b5280;
  --oreng-dark: #ff5c00;
  --link: #0066ff;
  --green-th: #00b428;
  --green-wp: #02c66e;
  --yellow-th: #ce9400;
  --color-grey: #cbcbcb;

  --form-input: #f8f8f8;
  --border-color: #eeeeee;
  --border-color2: #e8e8e8;
  --gray-light: #bbbbbb;
  --gray-light2: #fafafa;
  --gray-mid: #777777;
  --gray-mid2: #cccccc;
  --gray-dark: #414141;
  --gray-dark2: #444444;
  --body-text-color: #555555;
  --swith: #aeaeae;

  --gren: #edffed;
  --oreng: #ffebec;
  --parpel: #efeafd;
  --blue: #eafbff;
  --blue2: #e1f4ff;
  --blue-mid: #f2faff;
  --aud-color: #f5fcf8;
  --use-bg: #fffcf8;
  --dash-bg: #f5f7fb;

  --light: 200;
  --regular: 200;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;

  --radius-10: 10px;
  --radius-20: 20px;
  --radius-30: 30px;
  --radius-50: 50px;
}

/* Common CSS */
body {
  font-family: var(--main-font) !important;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--main-font) !important;
  color: var(--black) !important;
  font-weight: var(--semi-bold) !important;
}

p,
span,
div {
  font-family: var(--main-font) !important;
}

h2 {
  font-size: 42px !important;
}

.fz-36 {
  font-size: 32px !important;
}

.fz-30 {
  font-size: 28px !important;
}

.fz-25 {
  font-size: 22px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-18 {
  font-size: 16px !important;
}

.fz-14 {
  font-size: 14px !important;
}

p {
  line-height: 1.2;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.border-none {
  border: none !important;
}
.no-content-container {
  font-size: 1.3rem !important;
}
/* Form style */
input.form-control,
textarea.form-control {
  outline: none !important;
  box-shadow: none !important;
  border-radius: var(--radius-50) !important;
  background-color: var(--white);
  padding: 13px 20px;
  font-size: 12px;
  font-weight: var(--semi-bold);
  margin-bottom: 0px;
  border: 1px solid var(--border-color);
}

input.form-control::placeholder,
textarea.form-control::placeholder {
  font-size: 14px;
  font-weight: var(--regular);
  color: var(--gray-light);
}

input.form-control[type="password"] {
  padding-right: 40px;
}

/* Form btns */
.sign-in {
  background: var(--primary);
  color: var(--white);
  font-weight: var(--bold);
  width: 100%;
  border: 1px solid var(--primary);
  padding: 10px 20px;
  border-radius: var(--radius-50);
  text-align: center;
}

a.btn,
button.btn {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  padding: 12px 45px;
  font-size: 16px;
  font-weight: var(--semi-bold);
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}

button {
  border: inherit;
  font-family: var(--main-font) !important;
}

button.MuiButton-contained {
  line-height: 1.2;
  background: var(--dashboard-blue);
  border-radius: var(--radius-50);
  display: inline-block;
  color: var(--white);
  padding: 12px 20px;
  font-size: 16px;
  font-weight: var(--bold);
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  text-align: center;
  text-transform: inherit;
  box-shadow: none;
}

button.MuiButton-text {
  line-height: 1.2;
  background: var(--secondary);
  border-radius: var(--radius-50);
  display: inline-block;
  color: var(--white);
  padding: 12px 20px;
  font-size: 16px;
  font-weight: var(--bold);
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  text-align: center;
  text-transform: inherit;
}

button.MuiButton-text:hover {
  background: var(--black);
}

button.MuiButton-outlined {
  line-height: 1.2;
  border-radius: var(--radius-50);
  border: 2px solid var(--red);
  display: inline-block;
  color: var(--red);
  padding: 12px 20px;
  font-size: 16px;
  font-weight: var(--bold);
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  text-align: center;
  text-transform: inherit;
}

button.MuiButton-outlined:hover {
  border: 2px solid var(--red);
  color: var(--white);
  background-color: var(--red);
}

/* Container Padding style */
.container {
  max-width: 1200px !important;
}

.common-padding {
  padding: 100px 0;
}

.common-small-padding {
  padding: 50px 0;
}

/* Common CSS */

/* header CSS */
.landing-header {
  padding: 15px 0;
}

.landing-header .btn {
  display: inline-block;
}

/* header CSS */

/* banner CSS */
.banner-section {
  position: relative;
  padding: 136px 0 45px;
}

.hero-banner-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 100%;
  z-index: -1;
}

img.hero-banner-line {
  position: absolute;
  z-index: -1;
  bottom: 135px;
  width: 100%;
  height: inherit;
}

img.hero-banner-right {
  position: absolute !important;
  right: 0 !important;
  bottom: 45px !important;
  width: calc(50% - 350px) !important;
  object-fit: contain !important;
  object-position: bottom !important;
}

img.hero-banner-left {
  position: absolute !important;
  left: 0 !important;
  bottom: 45px !important;
  width: calc(50% - 350px) !important;
  object-fit: contain !important;
  object-position: bottom !important;
  inset: 0 70% !important;
}

.banner-form {
  max-width: 472px;
  background: var(--white);
  margin: 0 auto;
  padding: 50px 30px;
}

.banner-form .form-control {
  background: var(--form-input) !important;
  border: none !important;
  margin-bottom: 20px;
}

.banner-form .form-control::placeholder {
  color: var(--gray-light);
}

.banner-form h2 {
  color: var(--body-text-color) !important;
  font-weight: var(--regular) !important;
  margin-bottom: 20px;
  line-height: 1;
  font-size: 30px !important;
}

.forgot-pass {
  font-size: 12px;
  font-weight: var(--semi-bold);
  color: var(--gray-dark);
  text-decoration: none;
  display: block;
  margin-bottom: 15px;
}

.or-class {
  display: block;
  text-align: center;
  color: var(--black);
  font-size: 18px;
  font-weight: var(--semi-bold);
  margin: 4px 0;
}

.ban-policy {
  margin-top: 24px;
  color: var(--gray-mid);
  font-size: 14px;
}

.ban-policy a {
  color: var(--secondary);
  text-decoration: none;
  display: inline-block;
}

.ban-join-now {
  display: block;
  text-align: center;
  margin: 20px 0 0;
  color: var(--secondary);
  font-weight: var(--semi-bold);
  font-size: 16px;
}

.sign-in-ban {
  background: var(--primary);
  color: var(--white);
  font-weight: var(--semi-bold);
  width: 100%;
  border: 1px solid var(--primary);
  padding: 8px;
  border-radius: var(--radius-50);
}

.ban-gsign-in {
  background: var(--white);
  color: var(--black);
  font-weight: var(--semi-bold);
  width: 100%;
  border: 1px solid var(--primary);
  padding: 9px;
  border-radius: var(--radius-50);
  font-size: 14px;
}

.ban-gsign-in img {
  margin-right: 5px;
  width: 21px;
}

/* banner CSS */

/* About CSS */
.about-section {
  padding: 100px 0 30px;
  text-align: center;
}

.about-box {
  max-width: 934px;
  margin: 0 auto;
}

.about-box p {
  /* max-width: 640px; */
  /* margin: 0 auto 30px; */
  color: var(--gray-mid);
  line-height: 1.2;
}

.about-box h2 {
  color: var(--black);
  margin-bottom: 40px;
}

.about-box a.btn {
  margin: 0 auto -20px;
}

/* About CSS */

/* usages CSS */
.gren {
  background-color: var(--gren) !important;
}

.oreng {
  background-color: var(--oreng) !important;
}

.parpel {
  background-color: var(--parpel) !important;
}

.blue {
  background-color: var(--blue) !important;
}

.usages-section {
  background: var(--use-bg);
}

.usages-Chip {
  text-align: center;
}

.usages-Chip .MuiChip-filled {
  font-size: 16px;
  font-weight: var(--bold);
  font-family: var(--main-font) !important;
  font-style: italic;
  padding: 13px 25px;
  height: inherit;
  border-radius: var(--radius-50);
  margin: 0 7px 15px;
  color: var(--black);
}

.usages-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.usages-box p {
  color: var(--body-text-color);
  line-height: 1.2;
  max-width: 440px;
  margin: 0;
}

.usages-box h2 {
  margin: 0;
}

/* usages CSS */

/* users CSS */
.users-tab {
  display: flex;
  align-items: center;
  background: var(--form-input);
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: var(--radius-50);
  gap: 5px;
  margin-bottom: 60px;
}

.users-tab a.btn {
  background: #d4ebff;
  color: #a0c3e2;
}

.users-tab a.btn.active {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  border-color: var(--primary);
}

.users-img {
  margin: 0 -15px;
  max-width: 570px;
}

.users-text p {
  max-width: 441px;
  color: var(--body-text-color);
  margin-bottom: 10px;
}

.users-text h2 {
  margin-bottom: 40px;
}

/* users CSS */

/* audience CSS */
.audience-section {
  background-color: var(--aud-color);
}

.audience-text {
  max-width: 441px;
  margin: 0 auto;
}

.audience-text p {
  color: var(--body-text-color);
  margin-bottom: 0px;
}

.audience-text h2 {
  margin-bottom: 40px;
}

.audience-img {
  max-width: 736px;
  margin: 0 -12px 0 auto;
}

/* audience CSS */

/* contact CSS */
.contact-section {
  position: relative;
  overflow: hidden;
  padding-bottom: 485px;
}

.contact-form {
  background: #fff6f2;
  padding: 50px;
  max-width: 800px;
  margin: 0 auto;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 40px;
}

.contact-form .form-control {
  background: var(--white) !important;
  border: none !important;
  /* padding: 21px 20px; */
  margin-bottom: 20px;
  border-radius: 15px;
}

.contact-form .form-control::placeholder {
  color: var(--gray-light);
}

.contact-form textarea.form-control {
  height: 120px;
  resize: none;
  margin-bottom: 20px;
}

.contact-form .contect-btn {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  padding: 15px 45px;
  font-size: 18px;
  font-weight: var(--semi-bold);
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

img.contect-left {
  position: absolute;
  left: 0px;
  top: 175px;
  width: 500px;
  z-index: -1;
}

img.contect-right {
  position: absolute;
  right: 0;
  top: 250px;
  width: 380px;
  z-index: -1;
}

img.contect-bg {
  position: absolute;
  bottom: -94px;
  z-index: -2;
}

/* contact CSS */

/* Footer CSS */
footer.main-footer {
  background: var(--primary);
}

a.foot-btn {
  background: transparent;
  border: 1px solid var(--white);
  text-decoration: none;
  border-radius: var(--radius-50);
  color: var(--white);
  padding: 18px 45px;
  font-size: 18px;
  font-weight: var(--semi-bold);
  margin: 0 0 0 30px;
}

a.foot-btn.white {
  background: var(--white);
  color: var(--primery);
}

.ftr-btm {
  padding: 20px 0;
  border-top: 1px solid var(--white);
  margin: 50px 0 0;
}

.ftr-btm-link ul {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  gap: 30px;
  padding: 0;
  margin: 0;
}

.ftr-btm-link ul a {
  color: var(--white);
  text-decoration: none;
  font-size: 14px;
}

.footer-copy p {
  color: var(--white);
  font-size: 14px;
  margin: 0;
}

/* Footer CSS */

/* Dashboard CSS */

/*Auth*/
.auth-banner {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.auth-ban-img {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  overflow: hidden;
  border-radius: var(--radius-30);
}

.auth-ban-img img {
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.auth-ban-img:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(11 82 128 / 70%);
}

.auth-ban-text {
  position: relative;
}

.auth-ban-text {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: auto;
  bottom: 80px;
  left: 90px;
}

.auth-ban-text span {
  font-size: 100px;
  font-weight: var(--light);
  letter-spacing: -10px;
  color: var(--white);
}

.auth-ban-text h1 {
  font-size: 120px;
  letter-spacing: -10px;
  font-weight: var(--bold) !important;
  max-width: 450px;
  line-height: 0.8;
  color: var(--white) !important;
}

.auth-form-left {
  height: 100vh;
  direction: rtl;
  overflow: auto;
  display: flex;
  padding: 50px 0px 50px 30px !important;
}

.auth-form-wrap {
  max-width: 450px;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.auth-logo {
  width: 80px;
  height: 80px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-50);
  padding: 12px;
  margin: 0 auto 20px;
}

.auth-heading {
  text-align: center;
  margin-bottom: 30px;
}

.auth-heading h2 {
  font-size: 40px !important;
  margin-bottom: 15px;
  font-weight: var(--medium) !important;
}

.auth-heading p {
  color: var(--gray-mid);
  margin: 0;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  margin: 0 0 6px;
  padding-left: 20px;
  color: var(--black) !important;
  font-weight: var(--medium);
  font-size: 14px;
}

.form-group label span {
  color: var(--red-n);
}

.form-group .show-pass {
  position: absolute;
  right: 20px;
  bottom: 34%;
}

.forgot-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.forgot-p {
  color: var(--secondary);
  text-decoration: inherit;
  font-size: 14px;
  font-weight: var(--medium);
}

label.remember-wrap {
  font-size: 14px;
  color: var(--black);
  display: flex;
  align-items: center;
}

label.remember-wrap a {
  color: var(--secondary);
  display: inline-block;
  text-decoration: inherit;
}

.other-signup-wrap {
  text-align: center;
}

.or-signup {
  position: relative;
  margin-bottom: 20px;
}

.or-signup:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: var(--border-color);
}

span.or {
  padding: 0 15px;
  background: var(--white);
  position: relative;
  font-size: 14px;
  color: var(--gray-mid);
  font-weight: var(--medium);
}

a.other-signup-icon {
  width: 50px;
  height: 50px;
  border: 1px solid var(--border-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--form-input);
  border-radius: var(--radius-50);
  margin: 0 5px;
}

span.other-signup-icon {
  width: 50px;
  height: 50px;
  border: 1px solid var(--border-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--form-input);
  border-radius: var(--radius-50);
  margin: 0 5px;
}

span.sign-up {
  font-weight: var(--bold);
  text-decoration: inherit;
  color: var(--secondary);
}

p.have-account {
  text-align: center;
  font-size: 14px;
  margin: 10px 0;
  color: var(--gray-mid);
  font-weight: var(--medium);
}

span.otp-timer {
  color: var(--secondary);
  font-weight: var(--bold);
  margin-top: 10px;
  display: block;
}

.otp-group {
  display: flex;
  /* max-width: 250px; */
  margin: 0 auto 20px;
  justify-content: center;
  gap: 10px;
}

.otp-group input.form-control {
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 30px;
  padding: 10px;
}

/*Feed*/
.dashboard-wrap {
  background: var(--dash-bg);
  min-height: 100vh;
  padding: 20px 15px 0 272px;
  transition: padding 0.5s;
}

.dash-logo-wrap {
  margin-bottom: 50px;
}

.dashboard-sidenav {
  width: 240px;
  background: var(--dashboard-blue);
  border-radius: 20px 20px 0 0;
  padding: 35px 15px 20px;
  position: fixed;
  height: calc(100vh - 20px);
  left: 15px;
  top: 20px;
  overflow: auto;
  z-index: 1;
  transition: width 0.5s;
}

/* width */
.dashboard-sidenav::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.dashboard-sidenav::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.dashboard-sidenav::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.dashboard-sidenav::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* SideNev menu */
.custom-sidenav {
  background-color: transparent !important;
  color: var(--white) !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0 !important;
  cursor: pointer;
  border-radius: var(--radius-10) !important;
  margin-bottom: 20px !important;
}

.custom-sidenav:before {
  display: none;
}

.custom-sidenav-list {
  padding: 12px 12px 12px 22px !important;
  min-height: inherit !important;
}

.custom-sidenav-list .MuiAccordionSummary-content {
  margin: 0 !important;
  transition: 300ms ease;
}

.custom-sidenav-list .sidenav-li {
  display: flex;
  gap: 20px;
  font-weight: var(--semi-bold);
  align-items: center;
}

.custom-sidenav .nav-arrow {
  width: 20px !important;
  height: 20px !important;
  color: var(--white);
}

.custom-sidenav.Mui-expanded {
  background: var(--white) !important;
  color: var(--dashboard-blue) !important;
}

.custom-sidenav.Mui-expanded .nav-arrow {
  color: var(--dashboard-blue);
}

.custom-sidenav.Mui-expanded .custom-sidenav-list .sidenav-li {
  font-weight: var(--semi-bold);
}

.custom-sub-menu {
  padding: 0 !important;
}

.custom-sub-menu ul {
  padding: 0 12px 15px;
  margin: 0;
  list-style: none;
}

.custom-sub-menu ul li {
  padding: 6px 6px;
  font-weight: var(--semi-bold);
  margin: 5px 0;
}

.custom-sub-menu ul li:hover {
  background: var(--sub-menu);
  color: var(--white) !important;
  border-radius: var(--radius-10);
}

.logout {
  color: var(--red) !important;
}

/* 
ul.dashboard-menu { padding: 0; margin: 0; list-style: none; }
ul.dashboard-menu li p {padding: 15px 20px 15px 30px;display: flex;align-items: center;color: var(--white);text-decoration: none;gap: 20px;  font-weight: var(--medium); margin: 0; cursor: pointer;} 
ul.dashboard-menu li { margin-bottom: 20px; border-radius: var(--radius-10); position: relative; }
ul.dashboard-menu li img.dashboard-menuIcon { width: 20px; }
ul.dashboard-menu li.active, ul.dashboard-menu li:hover { background:  var(--white); }
ul.dashboard-menu li.active p, ul.dashboard-menu li:hover p { color: var(--dashboard-blue); font-weight: var(--semi-bold); }

li.item-dropdown:after { content: url(/assets/arrow.svg); right: 20px; top: 25px; position: absolute; filter: brightness(0) invert(1);  line-height: 0; }
ul.dashboard-menu li.item-dropdown:hover:after { filter: inherit; transform: rotate(180deg);  }
ul.dashboard-menu li .sub-menu { display: none; list-style: none; padding:0 0 10px; }
ul.dashboard-menu li:hover .sub-menu { display: block;}
ul.dashboard-menu li .sub-menu li { padding-bottom: 4px; margin: 0; } 
ul.dashboard-menu li .sub-menu li p { width: calc(100% - 24px); margin-left: 12px; padding: 14px 15px; } 
ul.dashboard-menu li .sub-menu li p:hover { background: var(--sub-menu); color: var(--white) !important; border-radius: var(--radius-10); }
ul.dashboard-menu li a.logout { color: var(--red); }
 */

/* Top Nav menu */
.dash-top-nav {
  background: var(--white);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  display: flex;
  align-items: center;
  top: 20px !important;
  margin-bottom: 20px;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px -52px 0px rgb(243 245 249);
}

/*position: sticky;*/
.dash-top-nav h1 {
  margin: 0;
  font-weight: var(--bold) !important;
  margin-right: 30px;
}

.topnav-right {
  /* margin-left: auto; */
  display: flex;
  align-items: center;
  gap: 20px;
}

button.user-menu-btn {
  padding: 0 !important;
  background: transparent;
}

button.user-menu-btn:hover {
  background: transparent;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 18px;
  font-weight: var(--bold);
  color: var(--black);
  position: relative;
  padding-right: 20px;
}

.user-menu img {
  border-radius: 50%;
  object-fit: cover;
}

.user-menu span:after {
  content: url(/assets/arrow.svg);
  right: 0;
  top: 25%;
  transform: translate(0px, -50%);
  position: absolute;
  filter: brightness(0);
  line-height: 0;
}

.notification .MuiBadge-root img {
  max-width: 25px;
  position: relative;
}

.notification .MuiBadge-dot {
  background-color: var(--oreng-dark);
}

.notification img.notifi {
  width: 100%;
}

.src-box form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.src-box button {
  background: transparent;
  border: none;
  padding: 0;
}

.src-box input.search-inp {
  padding: 0 15px;
  border: none;
  outline: none;
}

/* Dashboard wrap */
.dashboard-content {
  flex: 1 0;
}

.feed-col-3 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.feed-common-col {
  flex: 0 0 auto;
  width: 24%;
  padding: 0 15px;
}

.feed-mid-col {
  flex: 1 0;
  padding: 0 15px;
}

/*profile component*/
.profile-component {
  background: var(--white);
  border-radius: var(--radius-20);
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
}

.profile-cover-img {
  height: 146px;
  border-radius: 0 0 var(--radius-20) var(--radius-20);
  background-size: cover;
  background-position: center;
  position: relative;
}

.profile-cover-img img {
  object-fit: cover;
}

.profile-img {
  text-align: center;
  position: relative;
}

.profile-img img.dp {
  height: 110px;
  width: 110px;
  border: 5px solid var(--white);
  margin-top: -50px;
  border-radius: 50%;
  background: var(--white);
  object-fit: cover;
}

.profile-text {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 10px 20px 40px;
}

.profile-text p {
  color: var(--gray-dark2);
  font-weight: var(--medium);
}

.profile-text button.MuiButton-contained {
  width: 100%;
  max-width: 270px;
}

/*Become company component*/
.become-company {
  text-align: center;
  margin-bottom: 30px;
}

.become-company button.MuiButton-contained {
  width: 90%;
  max-width: 255px;
}

.company-profile-component {
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  background: var(--white);
  border-radius: var(--radius-20);
  overflow: hidden;
  margin-bottom: 30px;
  padding: 10px 25px;
}

.company-links {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: inherit;
  padding: 20px 0;
}

.company-imgs {
  width: 60px;
  height: 60px;
  background: var(--form-input);
  border-radius: var(--radius-50);
  overflow: hidden;
}

.company-imgs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company-texts p {
  color: var(--gray-mid);
  margin: 0;
}

/*Post Job component*/
.post-job-box {
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  background: var(--white);
  border-radius: var(--radius-20);
  overflow: hidden;
  margin-bottom: 30px;
  padding: 30px 15px;
  text-align: center;
}

.post-job-box p {
  font-size: 18px;
  color: var(--gray-dark2);
  max-width: 528px;
  margin: 0px auto 24px;
}

.post-job-box p a {
  color: var(--secondary);
  font-weight: var(--semi-bold);
  text-decoration: inherit;
}

.post-job-wrap {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.post-job-wrap button.MuiButton-contained,
.post-job-wrap button.MuiButton-text {
  width: 100%;
  max-width: 270px;
  box-shadow: 0 6px 20px rgb(3 23 41 / 30%);
  height: 50px;
}

/*Feed Post component*/
.feed-posts {
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  background: var(--white);
  border-radius: var(--radius-20);
  margin-bottom: 30px;
  overflow: hidden;
}

.feed-post-content {
  padding: 40px 40px 30px;
  border-bottom: 1px solid var(--border-color);
}

.feed-post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.feed-post-links {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
}

.feed-post-menu {
  display: flex;
  height: 24px;
}

.feed-post-menu button.MuiButton-text {
  background: transparent;
  padding: 0 !important;
  width: inherit !important;
  min-width: 20px;
  height: inherit;
  margin: 0px 5px 0 0;
  color: var(--gray-mid2);
}

.post-cross {
  color: var(--gray-mid2);
}

.feed-link-texts h5 {
  margin: 0 0 4px;
}

.feed-link-texts p {
  color: var(--gray-mid);
  font-size: 14px;
  margin: 0;
  font-weight: var(--medium);
}

.feed-link-texts span {
  font-size: 14px;
  color: var(--secondary);
  font-weight: var(--medium);
}

.feed-post-body p {
  color: var(--gray-dark2);
}

.feed-post-body p a {
  color: var(--secondary);
  text-decoration: none;
}

.feed-post-image {
  position: relative;
  padding: 0 0 70%;
  overflow: hidden;
  border-radius: var(--radius-20);
}

.feed-post-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.feed-post-footer {
  margin: 20px 0 0;
}

.feed-post-interaction {
  display: flex;
  flex-wrap: wrap;
}

.feed-post-colum {
  flex: 1 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.feed-post-colum:not(:last-child) {
  border-right: 1px solid var(--gray-light);
}

.feed-post-colum img {
  max-width: 18px;
}

.feed-post-colum span {
  color: var(--gray-dark2);
  font-weight: var(--semi-bold);
}

.feed-notifications {
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  background: var(--white);
  border-radius: var(--radius-20);
  overflow: hidden;
  margin-bottom: 30px;
  padding: 40px 25px;
}

.notifications-flex {
  display: flex;
  gap: 15px;
  text-decoration: none;
  padding: 20px 12px;
  border-bottom: 1px solid var(--border-color);
}

.notifications-flex:last-child {
  border-bottom: inherit !important;
}

.notifications-content p {
  font-weight: var(--medium);
  color: var(--gray-dark2);
  margin-bottom: 8px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notifications-content button.MuiButton-contained,
.notifications-content button.MuiButton-outlined {
  padding: 12px 20px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 7px;
  font-weight: var(--bold);
  box-shadow: inherit;
}

.notifications-content button.MuiButton-outlined {
  padding: 10px 20px;
}

span.notification-time {
  color: var(--gray-mid);
  font-size: 14px;
  display: block;
}

.in-date {
  font-weight: var(--bold) !important;
  color: var(--secondary) !important;
}

.in-post {
  display: block;
  font-size: 14px;
  color: var(--black);
  font-weight: var(--bold);
}

.in-name {
  color: var(--secondary) !important;
  font-size: 12px;
  display: block;
  margin-bottom: 15px;
  font-weight: var(--medium);
}

.in-pby,
.in-city {
  font-size: 12px;
  display: block;
  font-weight: var(--medium);
  color: var(--gray-mid);
}

.in-pby span {
  color: var(--secondary);
  text-decoration: underline;
  font-weight: var(--bold);
}

.in-city {
  margin-bottom: 10px;
}

.show-more {
  color: var(--secondary);
  text-decoration: underline;
  font-weight: var(--bold);
  text-align: center;
  cursor: pointer;
}

.custom-menu .MuiMenu-paper {
  padding: 10px;
  border-radius: var(--radius-20);
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  border: 1px solid var(--border-color);
  width: 100%;
  max-width: 350px;
}

.custom-menu .MuiMenu-paper ul {
  padding: 0;
}

.custom-menu .MuiMenu-paper ul li {
  font-size: 14px;
  color: var(--black);
  padding: 8px 7px;
}

.custom-menu .MuiMenu-paper ul li.red {
  color: var(--red-n);
}

.custom-menu .MuiMenu-paper ul li:hover {
  background-color: transparent;
}

.custom-menu .MuiMenu-paper ul li:not(:last-child) {
  border-bottom: 1px solid var(--parpel);
}

.feed-apply-wrap {
  background-color: var(--gray-light2);
  border: 1px solid var(--border-color);
  border-top: 0;
  border-radius: 0 0 var(--radius-20) var(--radius-20);
  padding: 45px 30px 30px;
  margin: -20px 0 0;
}

.feed-apply-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feed-apply-head h5 {
  margin: 0;
  color: var(--gray-dark) !important;
}

.feed-apply-head button {
  max-width: 155px;
  width: 100%;
  padding: 10px 20px;
}

.feed-apply-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.feed-apply-body .feed-apply-data {
  flex: 1 0 auto;
}

.feed-apply-body .feed-apply-data p {
  margin: 0;
  color: var(--black);
  font-weight: var(--medium);
  padding-left: 16px;
}

.feed-apply-body .feed-apply-data label {
  font-weight: var(--semi-bold);
  color: var(--secondary);
  margin-bottom: 4px;
  padding-left: 14px;
  border-left: 2px solid var(--secondary);
  line-height: 1;
  display: flex;
  align-items: center;
}

.feed-apply-body .feed-apply-data label img {
  max-width: 12px;
  height: auto;
  margin-right: 5px;
}

/*Feed Post component*/

/*Feed Post comment*/
.feed-comment-section {
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
  margin-top: 30px;
}

.comment-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-input-bar {
  width: 100%;
  position: relative;
}

.comment-input-bar input {
  width: 100%;
  height: 60px;
  padding: 10px 160px 10px 20px;
  border: 1px solid var(--border-color);
  outline: none;
  border-radius: var(--radius-50);
}

.comment-input-btns {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.comment-send {
  background: var(--secondary) !important;
  transform: rotate(45deg);
  box-shadow: 0 6px 20px rgb(170 89 43 / 20%) !important;
  width: 50px !important;
  height: 50px !important;
}

.comment-attech {
  transform: rotate(220deg);
  color: var(--gray-light);
}

.comment-emoji {
  color: var(--gray-light);
}

.comment-final-wrap {
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.comment-wrap {
  display: flex;
  gap: 10px;
}

.comment-wrap-reply {
  display: flex;
  gap: 10px;
  padding: 20px 0 0 60px;
}

.comment-details {
  position: relative;
}

.comment-details h5 {
  font-weight: var(--bold) !important;
  margin-bottom: 5px;
}

.comment-details p {
  font-size: 12px;
  color: var(--gray-mid);
  margin: 10px 0;
}

.comment-details .com-text {
  font-size: 12px;
  display: block;
  color: var(--secondary);
  font-weight: var(--medium);
}

.com-time {
  position: absolute;
  top: 10px;
  right: 0;
  color: var(--gray-mid);
  font-size: 12px;
}

.com-inter .com-like,
.com-inter .com-reply {
  color: var(--link);
  font-weight: var(--medium);
}

.com-inter {
  font-size: 12px;
  line-height: 1;
}

/*Feed Post comment*/

.relative {
  position: relative;
}

label.cus-upload {
  width: 100%;
  background: transparent;
  border: 1px solid var(--border-color2);
  box-shadow: none;
  border-radius: 15px;
  color: var(--gray-mid);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: var(--semi-bold);
  text-transform: inherit;
  font-size: 14px !important;
  font-family: var(--main-font);
  padding: 36px 20px;
}

label.cus-upload:hover {
  background: transparent;
  box-shadow: none;
}

.upload-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 12px;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  transform-origin: 56px;
  margin-bottom: -8px;
  margin-left: 10px;
  padding: 0 5px;
  background: var(--white);
  position: relative;
  z-index: 1;
  width: -moz-fit-content;
  width: fit-content;
}

/* .common-card-style {
  background: var(--white);
  padding: 20px;
  border-radius: var(--radius-20);
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  min-height: calc(100vh - 150px);
} */

/* Modal CSS */
.common-modal-style {
  z-index: 9999;
}

.common-modal-style .MuiModal-backdrop {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.common-modal-box {
  width: 100%;
  border-radius: var(--radius-20);
  border: 1px solid var(--gray-mid2);
}

.modal-cross {
  position: absolute;
  right: 15px;
  top: 15px;
  color: var(--gray-mid2);
  cursor: pointer;
}

.modal-text p {
  color: var(--gray-mid);
  font-size: 14px;
  max-width: 600px;
  margin: 0 auto 1rem;
}

/* Account CSS */
.account-form-wrap {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.account-set-heading p {
  font-size: 12px;
  color: var(--gray-dark2);
}

.custom-swith-box {
  flex-direction: inherit !important;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
  background-color: var(--blue-mid);
  border-radius: var(--radius-20);
}

.custom-swith-box p {
  font-size: 14px;
  font-weight: var(--semi-bold);
  font-family: var(--main-font);
  color: var(--black);
}

.custom-swith {
  padding: 0 !important;
  width: inherit !important;
  height: inherit !important;
}

.custom-swith .MuiSwitch-thumb {
  width: 22px;
  height: 22px;
  box-shadow: inset 0 2px 10px rgb(0 0 0 / 10%);
  box-shadow: inset 0 2px 10px rgb(0 0 0 / 10%);
}

.custom-swith .MuiSwitch-track {
  padding: 0;
  width: 50px;
  height: 26px;
  border-radius: var(--radius-50);
  background: var(--swith);
  opacity: 1;
}

.custom-swith > .MuiSwitch-switchBase {
  padding: 0;
  top: 2px;
  left: 2px;
}

.custom-swith > .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--secondary);
  opacity: 1;
}

.custom-swith > .MuiSwitch-switchBase.Mui-checked {
  color: var(--white);
  transform: translateX(24px);
}

.MuiIconButton-edgeEnd {
  color: var(--swith) !important;
}

/* Dashboard CSS */

.menu-close-icon {
  position: absolute;
  left: -72px;
  top: 20px;
}

.menu-close-icon button {
  width: 27px;
  height: 27px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.hammenuBtn svg {
  width: 30px;
  height: 30px;
}

.hammenuBtn {
  display: none !important;
}

/*--------- Global Sanu CSS ----------*/
.fz-14-all * {
  font-size: 14px;
}

.mb-40 {
  margin-bottom: 40px;
}

.c-secondary-all * {
  color: var(--secondary);
}

.c-secondary {
  color: var(--secondary) !important;
}

.c-red {
  color: var(--red) !important;
}

.fw-500-all * {
  font-weight: 500;
}

.fw-600-all * {
  font-weight: 600;
}

.fw-700-all * {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.ls-normal {
  letter-spacing: normal;
}

.dashboard-content {
  padding-bottom: 20px;
}

.red-icon-btn {
  color: var(--red) !important;
  background-color: var(--oreng) !important;
}

.black-icon-btn {
  color: var(--black) !important;
  background-color: var(--border-color) !important;
}

.white-icon-btn {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.job-form-wrap input,
.cus-inp .MuiInputBase-multiline {
  padding: 18px 20px 20px 20px;
}

.job-form-wrap .row > div {
  margin-bottom: 25px;
}

.cus-inp .MuiOutlinedInput-notchedOutline {
  border-radius: 15px;
  border-color: var(--border-color2);
}

body .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color2) !important;
}

.cus-inp .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
  border-color: #a5a5a5;
}

.cus-inp input,
.cus-inp .MuiSelect-select,
.MuiMenu-list li,
.cus-inp textarea,
.cus-chk-inp .MuiTypography-root {
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: normal;
}

.cus-inp .MuiSelect-select {
  padding: 17px 40px 18px 20px;
}

.cus-inp .MuiSelect-multiple {
  padding: 13px 40px 13px 20px;
  box-sizing: border-box;
  min-height: 58px !important;
}

.has-icon {
  position: relative;
}

.inp-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.has-icon input {
  padding-right: 60px;
}

.custom-chip-inp .MuiChip-filled {
  background-color: var(--secondary) !important;
  border-radius: 5px !important;
}

.custom-chip-inp .MuiChip-filled * {
  color: #fff !important;
}

.MuiChip-deleteIcon {
  font-size: 19px;
}

.cus-chk-inp .Mui-checked svg {
  color: var(--link);
}

.cus-chk-inp .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

.addi-info-wrap {
  display: flex;
  align-items: center;
}

.addi-icon-wrap {
  flex: 0 0 80px;
  margin-left: 30px;
}

.addi-icon-wrap button {
  color: var(--secondary);
}

.addi-icon-wrap button svg {
  font-size: 35px;
}

.addi-input-wrap {
  width: 100%;
}

.addi-icon-wrap .icon-c-red {
  color: var(--red-n);
}

.addi-icon-wrap .icon-c-green {
  color: var(--green-n);
}

.addi-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nu-btn {
  min-height: 50px;
}

.cus-tab button.MuiTab-root {
  padding-left: 0;
  padding-right: 0;
  min-width: 0px;
  text-transform: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--gray-mid2);
  margin-right: 35px;
}

.cus-tab .MuiTabPanel-root {
  padding: 35px 0 0 0 !important;
}

.cus-tab button.MuiTab-root.Mui-selected {
  color: var(--secondary);
}

/* .cus-inp label { font-family: var(--main-font); color: #031729; font-size: 14px; font-weight: 500; background-color: #FFF; display: inline-block; padding-left: 5px; padding-right: 5px; } */
/* .cus-inp .MuiInputLabel-shrink { transform: scale(1) translate(14px, -15px) } */
/* .cus-inp legend { display: none;} */

.job-infobox-single {
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 1px 10px 30px rgba(0, 0, 0, 0.03);
  height: 100%;
  margin: 20px 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.job-infobox-single.closed-job {
  background: var(--border-color);
}

.job-infobox-header {
  display: flex;
}

.job-icon {
  margin-right: 10px;
  position: relative;
  height: 50px !important;
  width: 50px !important;
  flex: 0 0 auto;
}

.job-icon img {
  height: 50px !important;
  width: 50px !important;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
  padding: 1px;
}

.job-infobox-header p {
  margin-bottom: 7px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.job-infobox-header h4 {
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.job-infobox-header h4 small {
  font-size: 80%;
  font-weight: 500;
  color: var(--green-th);
  padding-left: 5px;
}

.job-infobox-header h4 small.tbd-txt {
  color: var(--yellow-th);
}

.job-action-icons {
  margin-left: auto;
  flex: 0 0 auto;
}

.job-action-icons button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  color: var(--secondary);
  background-color: var(--blue-mid);
}

.job-action-icons button:not(:last-child) {
  margin-right: 10px;
}

.job-infobox-header {
  border-bottom: 1px solid var(--border-color);
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px 20px 20px;
  gap: 10px;
}

.job-title-wrap {
  flex: 1 1;
  overflow: hidden;
}

.job-key-info-wrap {
  padding-top: 0px;
  margin-bottom: 0px;
}

.job-key-info-wrap ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-between;
}

.job-key-info-wrap ul li {
  padding-left: 16px;
  white-space: nowrap;
  /* width: calc(25% - 35px); */
  text-overflow: ellipsis;
  overflow: hidden;
}

.job-key-info-wrap ul li:not(:last-child) {
  margin-right: 20px;
}

.job-key-info-wrap ul li span {
  color: var(--secondary);
  font-weight: 700;
  display: block;
  position: relative;
}

.job-key-info-wrap ul li span svg {
  max-width: 15px;
  margin-right: 5px;
}

.job-key-info-wrap ul li span::after {
  content: "";
  height: 15px;
  width: 1px;
  background-color: var(--secondary);
  display: block;
  position: absolute;
  left: -16px;
  top: 4px;
}

.job-short-desc p {
  color: var(--swith);
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.job-short-desc {
  padding-top: 20px;
  padding-right: 40px;
}

.btn-small {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  min-height: 40px !important;
}

.job-infobox-wrap > .row > div {
  margin-bottom: 30px;
}

.details-is-open .common-card-style {
  width: calc(50% - 15px);
}

.details-is-open .common-card-style .job-infobox-wrap .row .col-lg-6 {
  width: 100%;
}

.job-details-sidebar {
  display: none;
  position: relative;
}

.job-details-sidebar span.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000;
  cursor: pointer;
}

.details-is-open .job-details-sidebar {
  display: block;
  width: calc(50% - 15px);
  /* position: sticky;
  top: 100px; */
}

.details-is-open {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.job-details-sidebar {
  background: var(--white);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
}

.job-details-sidebar .job-infobox-header {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.job-provider-info {
  margin-left: auto;
  text-align: right;
  padding-right: 20px;
}

.job-provider-info a {
  color: var(--secondary);
  display: block;
}

.job-list-info {
  padding-top: 30px;
}

.job-list-info ul {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  position: relative;
  padding-right: 160px;
  margin-bottom: 0;
}

.job-list-info ul span {
  font-weight: 600;
  color: var(--secondary);
  flex: 0 0 165px;
  position: relative;
  padding-left: 10px;
}

.job-list-info ul .list-work-wrap {
  display: inline-flex;
  justify-content: flex-start;
}

.job-list-info ul .list-work-wrap span.list-work {
  width: auto;
  flex: 0 0;
  padding-right: 10px;
  color: #212529;
}

.job-list-info ul .list-work-wrap span.list-work:after {
  background-color: #212529;
  width: 1px;
}

.job-list-info ul span svg {
  font-size: 20px;
  margin-right: 6px;
}

.job-list-info ul div {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 165px);
}

.job-list-info ul span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 2px;
  height: 15px;
  background-color: var(--secondary);
}

.job-list-info ul span img {
  width: 16px;
  height: auto;
  margin-right: 6px;
  transform: translateY(-1px);
}

.job-list-info ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.job-list-info ul li.salary-li {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.job-list-info ul li.salary-li span img {
  width: 20px;
  margin-right: 3px;
  transform: translateY(-1px);
}

.job-list-info ul li.salary-li div {
  margin-bottom: 0;
  padding-left: 14px;
  padding-top: 5px;
  font-size: 16px !important;
  max-width: 170px;
  word-break: break-all;
}

.tech-stack {
  margin-bottom: 30px;
}

.tech-stack h6 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.tech-stack ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  text-align: center;
  flex-wrap: wrap;
  row-gap: 20px;
}

.tech-stack ul li {
  margin-right: 10px;
}

.tech-stack ul li img {
  margin-bottom: 10px;
}

.tech-stack ul li p {
  margin-bottom: 0;
  font-size: 14px;
}

.job-main-details {
  margin-bottom: 25px;
}

.job-main-details h6 {
  margin-top: 0;
  font-weight: 700 !important;
  margin-bottom: 10px;
  margin-top: 30px;
}

.job-main-details p {
  color: var(--swith);
  line-height: 1.5;
  font-size: 14px;
}

.job-main-details :is(ul, ol) {
  padding-left: 0;
  font-size: 14px;
}

.job-main-details li {
  list-style: none;
  color: var(--black);
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}

.job-main-details li::after {
  content: "";
  width: 16px;
  height: 12px;
  display: block;
  background-image: url("/assets/tick-li.svg");
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}

.deadline-date p {
  color: var(--black);
  font-weight: 500;
}

.deadline-date p span {
  color: var(--red-n);
  font-weight: 700;
}

button.MuiButton-outlined.btn-black {
  color: var(--black);
  border: 2px solid var(--black);
}

button.MuiButton-outlined.btn-black:hover {
  background-color: var(--black);
  color: #fff;
}

button.MuiButton-text.btn-black {
  border: 2px solid var(--black);
  background-color: var(--black);
  color: var(--white);
}

button.MuiButton-text.btn-black:hover {
  background-color: var(--white);
  color: var(--black);
}

.abt-ful-image img {
  max-width: 100%;
  height: auto;
}

.job-details-sidebar .job-title-wrap {
  overflow: visible;
}

.job-provider-info p {
  word-break: break-all;
  white-space: pre-wrap;
}

.job-short-desc.has-more-text {
  position: relative;
  max-height: 100px;
  overflow: hidden;
}

.job-infobox-wrap .job-short-desc.has-more-text::after {
  content: "";
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
}

/* ========= Profile Pages CSS ============== */
.res-img-holder img {
  width: 100%;
  height: auto;
}

.border-rad-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.profile-view-banner {
  margin-bottom: -10px;
}

.profile-view-banner img {
  height: 40vh;
  object-fit: cover;
}

.MuiPopover-root.custom-menu {
  z-index: 2;
}

.user-info-holder h4 {
  font-weight: 600;
  margin-bottom: 3px;
}

.user-info-holder p {
  color: var(--gray-dark2);
  margin-bottom: 0;
  font-weight: 500;
}

.profile-sec-header {
  display: flex;
  justify-content: space-between;
}

.user-info-holder {
  margin-top: 20px;
}

.prof-btn-holder {
  padding-top: 130px;
}

.user-pic img {
  border: 4px solid var(--white) !important;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-sec-header {
  margin-top: -120px;
}

.prof-com-info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.prof-act-btn-2 {
  padding-top: 10px;
}

.comp-info {
  flex: 1 1;
}

.comp-info ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 20px;
  max-width: 602px;
  justify-content: space-between;
}

.comp-info ul span {
  color: var(--secondary);
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}

.comp-info ul li {
  font-weight: 700;
  color: var(--black);
}

.comp-info ul li p {
  min-width: 100px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.profile-tab-cus.cus-tab {
  border-top: 1px solid var(--border-color2);
}

.about-me-holder h3 {
  margin-bottom: 30px;
}

.about-me-holder p {
  color: var(--gray-dark2);
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 30px;
  word-wrap: break-word;
  /* Ensures long words break to the next line */
  word-break: break-word;
}
.more-btn.toggle-button {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--dashboard-blue) !important;
  font-size: 16px;
  margin: 0px;
}
.about-me-content p {
  margin-bottom: 15px;
}

.about-me-content {
  padding: 20px 0;
}
.full-text-wrap .more-btn svg {
  transform: rotate(180deg);
}

.more-btn svg {
  transition: all 0.3s ease;
}
.job-title-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.about-me-holder {
  border-bottom: 1px solid var(--dashboard-blue);
}

.about-skills-holder,
.files-sec-holder {
  padding-top: 30px;
  border-bottom: 1px solid var(--dashboard-blue);
}

.about-skills-holder h5 {
  margin-bottom: 15px;
}

.tech-stack.style-2 li {
  display: flex;
  align-items: center;
}

.tech-stack.style-2 img {
  margin-bottom: 0;
  margin-right: 10px;
}

.files-sec-holder {
  padding-bottom: 30px;
}

.files-sec-holder h5 {
  margin-bottom: 15px;
}

.files-blocks-holder {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .files-blocks-holder li {
  margin-bottom: 15px;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color2);
  background-color: var(--gray-light2);
  border-radius: var(--radius-10);
  max-width: 500px;
  padding: 15px;
  display: flex;
  align-items: center;
  min-height: 102px;
} */
.preview-file.chat-pdf .files-blocks-holder li.file-preview-block {
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color2);
  background-color: var(--gray-light2);
  border-radius: var(--radius-10);
  padding: 12px 10px;
  display: flex;
  align-items: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-flow: column;
  width: 164px;
}
.files-blocks-holder li .files-text-holder h6 {
  font-weight: 600 !important;
  display: inline-block;
  width: 128px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 12px !important;
}

.files-blocks-holder li .files-image-holder {
  flex: 0 0 60px;
  margin-right: 10px;
  /* border: 1px solid var(--blue2); */
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.files-blocks-holder li .files-image-holder img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.files-blocks-holder h6 {
  font-size: 12px;
  font-weight: 800;
}

.files-blocks-holder p {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-mid);
  margin-bottom: 5px;
}

.files-blocks-holder span {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary);
}

.files-blocks-holder li:last-child {
  margin-bottom: 0;
}

.files-blocks-holder li .files-image-holder.style2 {
  flex: 0 0 70px;
  height: 70px;
  width: 70px;
  border-radius: var(--radius-10);
}

.files-blocks-holder.style3 h6 {
  color: var(--secondary) !important;
  margin-bottom: 0;
  font-size: 16px;
}

.files-blocks-holder.style3 li {
  padding-left: 30px;
}

.show-all p {
  text-decoration: underline;
  margin-bottom: 0;
}

.MuiButton-text.line-btn,
.MuiButton-text.line-btn:hover {
  color: var(--secondary);
  padding: 0;
  font-weight: 700;
  background: transparent !important;
  text-decoration: underline;
}

.post-image-ab {
  width: 200px;
  height: 141px;
  flex: 0 0 200px;
  margin-right: 30px;
}

.post-image-ab img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius-10);
}

.post-desc-holder p {
  color: var(--gray-dark2);
  display: inline;
}

.post-desc-holder button {
  padding: 0;
  padding-left: 5px;
  background-color: transparent;
  color: var(--dashboard-blue);
  font-weight: 600;
  text-decoration: underline;
}

.about-post-holder {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 40px;
  border-bottom: 1px solid var(--border-color2);
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.about-post-holder .feed-post-interaction .feed-post-colum {
  margin-right: 30px;
  flex: 0 0 auto;
  border-right: 0;
}

.about-post-holder .feed-post-interaction {
  justify-content: flex-start;
}

.about-post-holder .feed-post-footer {
  margin-top: 15px;
}

.about-post-holder .feed-post-menu {
  position: absolute;
  top: 0;
  right: 0;
}

.about-post-holder:last-of-type {
  border-bottom: 0;
}

.custom-menu.ab-post-popovr .MuiMenu-paper {
  max-width: 180px;
}

.invite-avatar img {
  object-fit: cover;
  width: 100%;
  border-radius: var(--radius-20);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
}

.invite-avatar {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.invite-avatar-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  margin-top: 20px;
}

.invite-dtls-right {
  border-left: 1px solid var(--border-color2);
  padding-left: 25px;
  margin-left: 10px;
}

.invite-prof-info-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.invite-prof-info-list li {
  position: relative;
  font-size: 12px;
  color: var(--secondary);
  font-weight: 600;
  padding-left: 40px;
}

.invite-prof-info-list li p {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
}

.invite-prof-info-list .icon-holder {
  position: absolute;
  left: 0;
  top: 5px;
}

.invite-dtls-right h4 {
  margin-bottom: 8px;
}

.invite-dtls-right p {
  margin-bottom: 20px;
}

.whatsapp-btn {
  background-color: var(--green-wp) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  width: 100% !important;
}

.whatsapp-btn img {
  margin-right: 5px;
}

.share-top-avatar h5 {
  margin-bottom: 5px;
}

.share-profile-top {
  background-color: var(--border-color);
  border-radius: var(--radius-10);
  padding: 20px;
  padding-bottom: 5px;
}

.share-top-avatar {
  display: flex;
  align-items: center;
}

.name-holder-shr p {
  margin-bottom: 0;
}

.share-top-avatar .avatar {
  flex: 0 0 46px;
  margin-right: 15px;
  min-width: 46px;
  max-width: 46px;
  height: 46px;
  border-radius: 50px;
  background: #b8cad6;
  box-shadow: 0 2px 10px rgb(0 0 0 / 25%);
  position: relative;
}
.share-top-avatar .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.write-something-wrap input {
  font-size: 20px;
  font-weight: 400;
  padding-left: 0;
}

.write-something-wrap * {
  border: 0 !important;
}

.search-inp-holder {
  position: relative;
  margin-top: 20px;
}

.search-inp-holder img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  opacity: 0.5;
}

.search-inp-holder input {
  font-size: 16px;
  font-weight: 400;
  padding-left: 50px !important;
}

.user-list-holder {
  padding-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 400px;
}

.user-list-holder ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.user-list-holder ul li {
  margin-bottom: 20px;
}

.user-list-holder button {
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  margin-left: auto;
  min-width: 71px;
}

.user-list-holder button.Mui-disabled {
  color: var(--gray-mid) !important;
}

.common-modal-style.big-modal-scroll {
  overflow: auto !important;
  margin-bottom: 20px;
}

.big-modal-scroll .common-modal-box {
  transform: translate(-50%, 0%) !important;
  top: 40px !important;
}

/* ========= Profile Pages CSS ============== */

/* ========= Edit Profile CSS ============== */
.edit-profile-bookmark {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  max-width: 300px;
  width: 100%;
  float: left;
  margin-right: 30px;
  z-index: 1 !important;
}

.edit-profile-details {
  width: calc(100% - 330px);
  margin-left: 330px;
}

.edit-profile-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.checkbox-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.checkbox-heading h5 {
  margin: 0;
}

.edit-checkbox .MuiFormControlLabel-label {
  font-weight: 500;
  color: var(--secondary);
}

.edit-checkbox .cus-chk-inp {
  margin-right: 0;
  margin-left: 6px;
}

.edit-checkbox .cus-chk-inp .MuiCheckbox-root {
  padding: 0 5px;
}

label.upload-profile {
  background: #f1f1f1;
  border-color: #f1f1f1;
  border-radius: 20px;
  padding: 50px 20px;
}

label.upload-profile svg {
  font-size: 40px !important;
  color: #fff;
}

label.upload-profile:hover {
  background: #f1f1f1;
}

.profile-image {
  position: relative;
  width: 148px;
  height: 148px;
  margin-bottom: 20px;
}

.cover-image {
  position: relative;
  height: 148px;
  margin-bottom: 20px;
}

.cover-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.cover-image .edit-icon {
  position: absolute;
  right: -15px;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: inherit;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.profile-image .edit-icon {
  position: absolute;
  right: -15px;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: inherit;
}

.delete-icon {
  top: 0 !important;
  background-color: var(--red) !important;
  cursor: pointer;
}

.edit-form-sec {
  border-top: 1px solid var(--dashboard-blue);
  padding-top: 30px;
  padding-bottom: 30px;
}

.add-more {
  background: transparent;
  color: var(--secondary);
  font-weight: 700;
  /* text-decoration: underline; */
}

.remove-more {
  background: transparent;
  color: var(--red-n);
  font-weight: 700;
  /* text-decoration: underline; */
}

.edit-profile-bookmark {
  background: var(--white);
  padding: 25px;
  border-radius: 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
}

.edit-profile-bookmark ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.edit-profile-bookmark ul li {
  padding: 15px;
  cursor: pointer;
  position: relative;
}

.edit-profile-bookmark ul li:after {
  content: "";
  background: url(/assets/blue-arrow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: calc(50% - 5px);
  width: 8px;
  height: 12px;
  position: absolute;
  right: 0;
  opacity: 0;
}

.edit-profile-bookmark ul li:hover:after {
  opacity: 1;
}

.edit-profile-bookmark ul li:hover p {
  color: var(--secondary);
}

.edit-profile-bookmark ul li:not(:last-child) {
  border-bottom: 1px solid var(--dashboard-blue);
}

.edit-profile-bookmark ul li p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--dashboard-blue);
  line-height: 1.6;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

.job-bottom-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deadline-wrap {
  background: #f5f5f5;
  padding: 5px 20px;
  border-radius: 20px;
  color: var(--gray-dark2);
  font-weight: 500;
}

.deadline-wrap strong {
  color: var(--red-n);
}

.job-infobox-header .mj-match.good {
  color: #00b428;
}

.job-infobox-header .mj-match.avg {
  color: #ce9400;
}

.job-infobox-header .mj-match.red {
  color: var(--red);
}

.job-infobox-header .mj-match.close {
  color: var(--red);
  font-size: 14px;
  font-weight: 700;
}

.job-infobox-header .mj-match {
  font-size: 12px;
  font-weight: 500;
  margin-left: 0;
}

.new-job-filter {
  margin-bottom: 30px;
}

.new-job-filter .filter-input-wrap {
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 920px;
}

.new-job-filter .filter-input-wrap input {
  padding: 20px 20px;
}

.new-job-filter .filter-input-wrap button.MuiButton-text {
  border-radius: 15px;
  font-size: 18px !important;
}

ul.filter-sugg-tag {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0 0;
  flex-wrap: wrap;
}

ul.filter-sugg-tag li {
  background: var(--gray-light);
  border-radius: 5px;
  padding: 7px 8px;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

ul.filter-sugg-tag li svg {
  width: 18px;
  height: 18px;
}

/* ========= Edit Profile CSS ============== */

/* ========= Pradipta 6.5.2024 ============== */

.common-card-style.no-min-hight {
  min-height: inherit;
}

.pm-0 p {
  margin-bottom: 0;
}

.job-tabs .MuiTabs-root {
  gap: 5px;
}

.job-tabs .MuiTabs-root .MuiTabScrollButton-root {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  top: 12px;
  position: relative;
}

.job-tabs .MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

/* job's pages */
.job-infobox-header.job-infobox-top-hdr {
  border-bottom: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.job-infobox-header.job-infobox-top-hdr::after {
  content: "";
  position: absolute;
  width: 1px;
  background: var(--border-color);
  height: calc(100% + 20px);
  top: 0;
  right: 0;
}

.job-listing-rows {
  background-color: var(--form-input);
  padding: 30px;
  border-radius: 20px;
}

.job-listing-user-img {
  display: flex;
}

.job-listing-user {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.job-status-top .job-short-desc p {
  max-width: 620px;
}

.job-listing-udetails h6 {
  margin-bottom: 0;
}

.job-listing-udetails .job-listing-uname {
  color: #999;
}

.job-listing-udetails .job-listing-match {
  font-size: 12px;
  font-weight: 500;
}

.job-listing-udetails .job-listing-match.green {
  color: #00b428;
}

.job-listing-udetails .job-listing-match.red {
  color: var(--red);
}

.job-listing-udetails .job-listing-match.gray {
  color: #999999;
}

.job-listing-udetails .job-listing-match.org {
  color: #ce9400;
}

.job-listing-udetails .job-listing-des {
  color: var(--gray-dark2);
  font-size: 12px;
  font-weight: 500;
}

.job-listing-udetails .job-listing-add {
  color: var(--secondary);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.job-listing-icons {
  display: flex;
  gap: 6px;
}

.job-listing-icons button {
  box-sizing: border-box;
  width: 50px;
  padding: 0 !important;
  min-width: inherit;
  height: 50px;
  color: var(--secondary);
  background-color: rgb(26 138 201 / 10%) !important;
  border: none;
}

.job-listing-icons button.job-btnIcon2 {
  background-color: rgb(0 45 85 / 10%) !important;
}

.job-listing-Resume > h6 {
  color: var(--secondary) !important;
  font-weight: 500 !important;
}

.job-listing-btn {
  display: flex;
  flex-direction: column;
}

.job-listing-btn button {
  min-width: inherit;
  width: 100%;
  max-width: 164px;
  margin-left: auto;
}

.job-listing-info ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 80%;
  flex-wrap: wrap;
  margin-left: 132px;
  row-gap: 30px;
}

.job-listing-info ul span {
  color: var(--secondary);
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}

.job-listing-info ul li {
  font-weight: 700;
  color: var(--black);
  flex-basis: 25%;
}

.job-listing-stack h6 {
  color: var(--secondary) !important;
}

.job-listing-end {
  max-width: 80%;
  flex-wrap: wrap !important;
  margin-left: 120px !important;
}

ul.job-tech-stack {
  display: flex;
  list-style: none;
  padding-left: 0;
  text-align: center;
  gap: 30px;
}

ul.job-tech-stack p {
  margin: 10px 0 0;
  font-weight: 600;
  font-size: 14px;
}

.job-listing-hidden {
  display: none;
}

.job-listing-hidden.show {
  display: block;
}

.jl-arrow {
  transition: 0.5s;
}

.jl-arrow.show {
  transform: rotate(180deg);
}

.job-listing-additional-qst {
  margin: 30px 0 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid var(--gray-mid2);
  box-shadow: 4px 4px 25px rgb(0 0 0 / 8%);
  max-width: 956px;
}

.job-listing-additional-qst h6 {
  font-weight: 700 !important;
  margin-bottom: 10px;
}

.job-listing-additional-qst ol {
  padding-left: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
  font-size: 14px;
}

.job-listing-additional-qst ol p {
  margin: 0 0 5px;
  color: var(--secondary);
}

.job-listing-additional-qst ol span {
  font-weight: 700;

  display: block;
  background-image: url(/assets/left-arrow.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: left center;
  padding-left: 20px;
  line-height: 1.4;
  margin: 0 0 10px -14px;
}

.job-listing-collps-btn {
  margin: 10px 0 -22px;
}

.job-listing-like {
  line-height: 1;
  margin-left: 5px;
}

.job-listing-like img {
  max-width: 15px;
}

.pay-mods {
  margin-left: 30px;
  height: auto;
  width: 100%;
  max-width: 300px;
}

.payment-detils-side h5 {
  color: var(--secondary) !important;
  font-weight: 700 !important;
}

.payment-detils-side h5 span {
  font-size: 12px;
  font-weight: 400;
}

.pay-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pay-table-header label {
  color: var(--sub-menu);
  font-weight: 700;
}

.pay-table-post {
  display: flex;
  justify-content: space-between;
}

.pay-table-post p {
  color: var(--black);
}

.pay-infobox-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pay-infobox-header h4 {
  margin: 0 0 4px;
}

.pay-infobox-header p {
  font-size: 12px;
  color: var(--secondary);
  margin: 0;
}

.pay-table-post {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f3f3f3;
  align-items: center;
}

.pay-table-post .pay-price p {
  margin-bottom: 0;
}

.pay-table-subtotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pay-table-total {
  display: flex;
  justify-content: space-between;
}

.pay-table-subtotal p,
.pay-table-total h5 {
  margin: 0;
  color: var(--black) !important;
}

.pay-icon img {
  width: 59px;
  height: 59px;
  object-fit: cover;
  border-radius: 50px;
}

.th-left-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  max-width: 340px;
  width: 100%;
  float: left;
  margin-right: 30px;
  background: var(--form-input);
  padding: 10px 25px;
  border-radius: 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
}

.th-left-sidebar ul li {
  padding: 15px;
  cursor: pointer;
  position: relative;
}

.th-left-sidebar ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.th-left-sidebar ul li:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}

.th-left-sidebar ul li p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}

.th-left-sidebar ul li:after {
  content: "";
  background: url(/assets/blue-arrow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: calc(50% - 5px);
  width: 8px;
  height: 12px;
  position: absolute;
  right: 15px;
  filter: brightness(0);
}

.th-left-sidebar ul li:hover p,
.th-left-sidebar ul li.active p {
  color: var(--secondary);
}

.th-left-sidebar ul li:hover:after,
.th-left-sidebar ul li.active:after {
  filter: brightness(1);
}

.th-right-wrap-sec {
  width: calc(100% - 440px);
  margin-left: auto;
  border: 1px solid var(--border-color2);
  border-radius: 15px;
  padding: 40px 40px 30px;
}

.th-table-dtls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.th-table-dtls h4 {
  margin-bottom: 0;
}

.th-table-dtls img {
  width: 30px;
  height: 30px;
  min-width: 30px;
  object-fit: cover;
  border-radius: 50px;
  overflow: hidden;
}

.th-table-box table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin: 0;
}

.th-table-box .table * {
  font-size: 12px;
  font-weight: 600;
}

.th-table-box .table thead th {
  border: none;
  padding: 10px 15px 0 !important;
  color: var(--secondary);
}

.th-table-box .table thead th:first-child {
  padding-left: 30px !important;
}

.th-table-box .table tbody tr {
  box-shadow: 0 6px 30px rgb(0 0 0 / 2%);
  border-radius: 10px;
  vertical-align: middle;
}

.th-table-box .table tbody td {
  padding: 10px 15px;
}

.th-table-box .table tbody td:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 1px solid var(--gray-light2);
  padding-left: 30px;
}

.th-table-box .table tbody td:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 1px solid var(--gray-light2);
}

.th-table-box .table tbody td {
  border-top: 1px solid var(--gray-light2);
  border-bottom: 1px solid var(--gray-light2);
}

button.light-blue-btn {
  background: var(--blue-mid);
  padding: 6px 15px;
  border-radius: 5px;
  border: 1px solid var(--blue2);
  color: var(--sub-menu);
  line-height: 1.6;
}

button.light-blue-btn:hover {
  background: var(--blue-mid);
}

button.light-blue-btn.red {
  background: #fff5f5;
  padding: 6px 15px;
  border-radius: 5px;
  border: 1px solid #ffd3d3;
  color: var(--red);
}

.tbl-status.paid {
  color: #00b912;
}

.tbl-status.cancel {
  color: var(--red);
}

.receipt-box {
  border: 1px solid #fafafa;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgb(0 0 0 / 5%);
}

.receipt-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
}

.receipt-head h6 span {
  color: var(--secondary);
}

.receipt-head p {
  font-size: 14px;
  color: var(--gray-light);
  font-weight: 500;
}

.receipt-head h6,
.receipt-head p {
  margin: 0;
}

.bill-details {
  margin-bottom: 20px;
}

.bill-details label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.bill-details h6 {
  font-weight: 500 !important;
  margin-bottom: 10px;
}

.bill-details p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--gray-mid);
  max-width: 195px;
}

.receipt-table-sec label {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 12px;
}

.receipt-table * {
  font-size: 12px;
  font-weight: 600;
}

.receipt-table thead tr th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  color: #999999;
  font-weight: 400;
  padding: 8px 15px;
}

.receipt-table tbody tr td {
  border: none;
  padding: 10px 15px;
}

.receipt-table tfoot tr th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 6px 15px;
  font-size: 14px;
}

button.small-btn {
  font-size: 10px;
  padding: 9px 15px;
}

.receipt-box:not(:last-child) {
  margin-bottom: 30px;
}

.pay-gateway-box {
  border: 1px solid #fafafa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgb(0 0 0 / 5%);
}

.pay-gateway-box h6 {
  margin-bottom: 15px;
}

img.pay-img {
  max-width: 50px;
  margin-bottom: 15px;
}

.pay-save-card {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.pay-save-card label {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: var(--secondary);
  line-height: 1;
}

.pay-save-card span {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}

.pay-save-card {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 16px;
}

.pay-gateway-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pay-gateway-btns button.light-blue-btn {
  font-size: 12px;
}

.common-card-style.chat-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  height: calc(100vh - 150px);
  overflow: hidden;
}

.chat-sidebar .cus-tab .MuiTabPanel-root {
  padding: 0 !important;
}

.chat-src-box {
  margin-top: 30px;
}

.chat-src-box form {
  position: relative;
}

.chat-src-box form input::placeholder {
  color: var(--gray-light);
}

.chat-src-box form button {
  background: transparent !important;
  padding: 0;
  min-width: inherit;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.archived-section {
  margin: 20px 0 10px;
  background: var(--secondary);
  color: #fff;
  border-radius: 15px;
  padding: 18px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.archived-section * {
  margin: 0;
}

.archived-section p {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  line-height: 1;
}

.archived-section p svg {
  font-size: 22px;
}

.archived-count {
  background: #fdfdfd;
  color: var(--black);
  font-size: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 20px;
  line-height: 1;
}

.chat-contact-list {
  display: flex;
  gap: 10px;
  padding: 20px 10px 20px 20px;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  cursor: pointer;
}

.chat-contact-list:hover {
  background-color: var(--dash-bg);
  border-radius: 15px;
  border: 1px solid #ececec;
  box-shadow: 6px 9px 24px rgb(0 0 0 / 5%);
}

.chat-user-img {
  border: 2px solid #b8cad6;
  min-width: 46px;
  max-width: 46px;
  height: 46px;
  border-radius: 50px;
  background: #b8cad6;
  box-shadow: 0 2px 10px rgb(0 0 0 / 25%);
  position: relative;
}

.chat-user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.chat-user-name-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.chat-user-name-time * {
  margin: 0;
}

.chat-user-name-time h6 {
  font-weight: 600 !important;
  display: inline-block;
  width: 203px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 14px;
}

.chat-user-name-time span {
  font-size: 12px;
  color: var(--gray-dark2);
}

.chat-msg-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-msg-preview p {
  margin: 0;
  font-size: 12px;
  color: var(--gray-mid);
  display: inline-block;
  width: 203px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.chat-contacts {
  overflow: auto;
  height: calc(100vh - 309px);
  padding-right: 15px;
  margin-right: -12px;
}
.main-tab .chat-contacts {
  height: calc(100vh - 377px);
}
.chat-contacts::-webkit-scrollbar {
  display: block !important;
}

/* Revert hiding scrollbar for IE and Edge */
.chat-contacts {
  -ms-overflow-style: auto !important;
  scrollbar-width: auto !important;
}

/* width */
.chat-contacts::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.chat-contacts::-webkit-scrollbar-track {
  background: #ebf3ff;
  width: 5px;
  border-radius: 5px;
}

/* Handle */
.chat-contacts::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 5px;
}

/* Handle on hover */
.chat-contacts::-webkit-scrollbar-thumb:hover {
  background: transparent;
  background: var(--secondary);
}

.chat-window {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  width: 100%;
  top: 160px;
  height: calc(100vh - 150px);
  background: var(--white);
  border-radius: 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  overflow: hidden;
}

.chat-window-header {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: var(--white);
}

.chat-window-header:after {
  content: "";
  position: absolute;
  left: 40px;
  right: 40px;
  height: 1px;
  background: var(--border-color);
  bottom: 0;
}

.chat-window-user {
  display: flex;
  align-items: center;
  gap: 15px;
}

.chat-window-image .msg-user {
  border: 2px solid #b8cad6;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  border-radius: 50px;
  background: #b8cad6;
  box-shadow: 0 2px 10px rgb(0 0 0 / 25%);
}

.chat-window-name h5 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.chat-window-name .active-status {
  font-size: 14px;
  color: var(--gray-mid);
}

.chat-window-name .active-status.online {
  color: var(--green-th);
}

.chat-window-dots button.MuiButton-text {
  background: transparent;
  color: var(--gray-dark);
  padding: 0 !important;
  min-width: inherit;
  font-size: 24px !important;
}

.chat-window-body {
  height: calc(100% - 153px);
}

.chat-area-main {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding-top: 20px;
}

.chat-msg {
  display: flex;
  padding: 0 40px 15px;
}

.chat-msg-profile {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: -20px;
  position: relative;
}

.chat-msg-date {
  position: absolute;
  left: calc(100% + 0px);
  bottom: -3px;
  font-size: 12px;
  color: var(--body-text-color);
  white-space: nowrap;
  font-weight: 500;
}

.chat-msg-content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.chat-msg-text {
  background-color: var(--form-input);
  color: var(--body-text-color);
  padding: 12px 25px 12px 20px;
  border-radius: 0px 15px 15px 15px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
}

.owner {
  flex-direction: row-reverse;
}

.owner .chat-msg-content {
  margin-left: 0;
  align-items: flex-end;
}

.owner .chat-msg-text {
  background-color: var(--secondary);
  color: var(--white);
  border-radius: 30px 0 15px 30px;
  padding: 10px 20px 10px 25px;
  word-break: break-all;
}

.owner .chat-msg-date {
  left: auto;
  right: calc(100% + 0px);
}

.chat-window-footer {
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px 20px;
}

.chat-input-area {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.schedule-btn {
  width: 100% !important;
  max-width: 176px;
  border-radius: 0 30px 30px 0 !important;
}

.chat-input-area {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.chat-input-area:after {
  content: "";
  position: absolute;
  height: 25px;
  width: 1px;
  background: var(--gray-light);
  left: 46px;
  top: 50%;
  transform: translateY(-50%);
}

.chat-input-area textarea.msg-bar {
  width: 100%;
  border: none;
  background: var(--form-input);
  padding: 20px 62px 20px 59px;
  min-height: inherit;
  font-size: 14px;
  outline: none;
  display: block;
  resize: none;
  border-radius: 30px 0px 0px 30px;
}
.chat-input-area textarea.msg-bar::-webkit-scrollbar {
  width: 2px;
}
.chat-src-box form fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}
.chat-window-name .chat-des {
  margin-bottom: 0;
  margin-top: 5px;
  color: #999999;
  font-size: 14px;
}

.chat-input-area textarea.msg-bar::placeholder {
  color: var(--gray-light);
}

.msg-send-btn {
  background: #ea6b3d !important;
  width: 40px !important;
  height: 40px;
  min-width: inherit !important;
  padding: 0 !important;
  position: absolute !important;
  right: 20px;
  top: 49%;
  box-shadow: 0 6px 20px rgb(234 107 61 / 50%) !important;
  transform: translateY(-50%);
}
.msg-send-btn svg {
  width: 20px;
}
.msg-attech {
  color: var(--gray-light);
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%) rotate(220deg);
}

.conversation-date {
  font-size: 12px;
  background: var(--border-color2);
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 15px;
  border-radius: 7px;
  color: var(--body-text-color);
  margin: 0 auto 10px;
}

.chat-msg.schedule {
  flex-direction: column;
  gap: 7px;
  padding-top: 20px !important;
}
.schedule .chat-schedule-pannel {
  padding-left: 9%;
  max-width: 444px;
}
.chat-schedule-box {
  max-width: 350px;
  background: var(--primary);
  border-radius: 10px;
  box-shadow: 4px 4px 25px rgb(0 0 0 / 5%);
}

.chat-schedule-box h5 {
  color: var(--white) !important;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid var(--dashboard-blue);
  margin-bottom: 10px;
}

.chat-schedule-data {
  padding: 10px 15px;
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
}

.schedule-dtls {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

.schedule-dtls label {
  min-width: 95px;
}

.schedule-dtls span {
  font-weight: 600;
}

.schedule-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
}

.schedule-btns button {
  width: 48%;
  font-size: 14px;
}

.schedule-btns button.MuiButton-outlined {
  background: var(--red-n);
  color: var(--white);
  border-color: var(--red-n);
}

.chat-schedule-reason {
  background: var(--blue-mid);
  width: 100%;
  max-width: 510px;
  border-radius: 10px;
  box-shadow: 4px 4px 25px rgb(0 0 0 / 5%);
}

.chat-schedule-reason h5 {
  padding: 15px 20px;
  border-bottom: 1px solid var(--white);
  color: var(--secondary) !important;
  font-weight: 700 !important;
  margin: 0;
}

.chat-schedule-reason p {
  padding: 15px 20px;
  color: var(--body-text-color);
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.chat-window-footer-inactive {
  padding: 17px 20px;
  text-align: center;
  background: var(--gray-light2);
  max-width: 62%;
  margin: 10px auto 30px;
  border-radius: 10px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
}

.chat-window-footer-inactive h6 {
  color: var(--gray-mid) !important;
  margin: 0;
}

.chat-sidebar .MuiTabs-scrollButtons {
  display: none;
}

.btn-wraps {
  display: flex;
  align-items: center;
}

.payment-dtls-right {
  margin-bottom: 30px;
}

.back-to-chat-menu {
  display: none;
}

.check-sidebyside .MuiFormGroup-root {
  flex-direction: row;
  gap: 20px;
}

.check-sidebyside .MuiFormGroup-root span {
  padding-top: 0;
  padding-bottom: 0;
}

.cus-slider {
  margin-bottom: 0 !important;
  margin-top: 30px !important;
  color: #0b5280 !important;
  height: 10px !important;
  padding: 0 !important;
}

.cus-slider .MuiSlider-rail {
  background: #eeeeee !important;
  opacity: 1 !important;
}

.cus-slider .MuiSlider-thumb {
  width: 13px !important;
  height: 13px !important;
  border: 1px solid #fff !important;
}

.cus-slider .MuiSlider-thumb:hover,
.cus-slider .MuiSlider-thumb.Mui-focusVisible,
.cus-slider.MuiSlider-dragging .MuiSlider-thumb {
  box-shadow: 0px 0px 0px 5px rgba(25, 118, 210, 0.16) !important;
}

.cus-slider .MuiSlider-mark {
  display: none;
}

.cus-slider .MuiSlider-markLabel {
  top: inherit;
  bottom: 25px;
  color: #000;
  font-weight: 600;
}

.cus-slider .MuiSlider-markLabel:after {
  content: "";
  height: 8px;
  width: 1px;
  background: #dfdfdf;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 0.5px);
}

/* ========= Pradipta 6.5.2024 ============== */

/* About Company Style */
.abt-company-holder p {
  color: var(--gray-dark2);
  line-height: 1.5;
}

.abt-company-holder h3 {
  margin-bottom: 30px;
}

.other-info-holder h3 {
  margin-bottom: 30px;
}

.location-dir-holder {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.loc-dir-inner {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.3333% - 15px);
}

.loc-dir-inner .loc-dir-pic {
  flex: 0 0 70px;
  aspect-ratio: 1 /1;
  border-radius: 50%;
  overflow: hidden;
}

.loc-dir-inner .loc-dir-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loc-dir-inner p {
  font-size: 14px;
  color: var(--gray-dark2);
  margin-bottom: 0;
}

.loc-dir-btn {
  width: 45px;
  height: 45px;
  border: 2px solid var(--secondary);
  margin-left: auto;
  background-color: transparent;
  border-radius: 50%;
  color: var(--secondary);
  transition: 300ms ease;
}

.loc-dir-btn:hover {
  background-color: var(--secondary);
  color: #fff;
}

.emp-count-sec .loc-dir-dtls {
  display: flex;
  align-items: center;
}

.emp-count-sec .loc-dir-dtls h4 {
  margin-bottom: 0;
  color: var(--secondary) !important;
  font-weight: 800 !important;
  padding-right: 5px;
}

/* About Company Style */

/* Apply to a job Style */
.addi-req-div {
  margin-top: 5px !important;
}

.addi-inp-holder label {
  padding-left: 0px;
  font-size: 14px;
  margin-bottom: 10px;
  white-space: normal;
}

.addi-req-div .header-sec {
  margin-bottom: 25px;
}

.addi-inp-holder {
  margin-bottom: 20px;
}

.apply-job-header-card {
  min-height: auto;
  margin-bottom: 30px;
}

.apply-job-header-card .job-detls-header {
  display: flex;
}

.apply-job-header-card .job-infobox-header {
  border-bottom: 0;
  padding-bottom: 0;
  padding: 0;
  margin: 0;
  border-right: 1px solid var(--border-color);
  flex: 0 0 30%;
}

.apply-job-header-card .job-details-holder {
  padding-left: 30px;
}

.apply-job-header-card .job-details-holder p {
  color: #999999;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 0;
}

.apply-job-header-card .job-key-info-wrap {
  padding-top: 0;
}

/* Apply to a job Style */

.update-info-account {
  position: relative;
  margin-bottom: 15px;
}

.save-btn-wrap {
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
}

.rev-cand-row {
  margin-top: 20px;
}

.rev-cand-row .previewImage {
  height: 100%;
}

.rev-cand-row .files-blocks-holder li .files-image-holder img {
  width: auto;
}

.rev-cand-row .job-listing-hidden .job-listing-info ul {
  max-width: 100%;
  margin-left: 0;
}

.rev-cand-row ul.job-tech-stack {
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.candidate-hidden-area {
  margin-top: 20px;
}

.candidate-hidden-area .col-md-5 {
  width: 100%;
}

.rev-cand-row .job-listing-item-img {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.close-reason-div {
  background: rgba(253, 241, 241, 1);
  padding: 20px;
  border-radius: 10px;
}

.your-own-job-div {
  background-color: #ffffe0;
  padding: 20px;
  border-radius: 10px;
}

.your-own-job-div p {
  margin-bottom: 0;
  text-align: center;
}
.completed-interview-div {
  background-color: red;
  padding: 20px;
  border-radius: 10px;
}

.completed-interview-div p {
  margin-bottom: 0;
  text-align: center;
}

.close-reason-div h6 {
  margin-top: 0;
}

.close-reason-div p {
  margin-bottom: 0;
}

.job-details-sidebar .job-main-details {
  margin-bottom: 0;
}

.closedSection .job-infobox-single {
  background-color: #eeeeee;
}

.closedSection .job-short-desc.has-more-text::after {
  display: none;
}

.candidate-table {
  width: 100%;
}

.candidate-table thead td {
  color: var(--secondary);
}

.candidate-table-row {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(250, 250, 250, 1);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-bottom: 10px;
}

.candidate-table-row > div {
  padding: 20px;
  color: var(--body-text-color);
  font-weight: 600;
  flex: 0 0 33.33%;
}

.btn-red:hover {
  background-color: var(--red) !important;
  color: #fff !important;
}

.table-header-row > div {
  color: var(--secondary);
  padding: 10px 20px;
}

.table-header-row {
  box-shadow: none;
  border: none;
}

.candidate-table-scroll {
  overflow: auto;
}

/* .candidate-table {
  min-width: max-content
} */

/* .closedSection .mj-match {
  color: var(--red) !important;
} */

.expandable-div-holder .job-listing-info ul li {
  flex-basis: 20%;
}

.btn-flex-holder.job-action-btn-full .btn-small {
  width: -moz-fit-content;
  width: fit-content;
  max-width: inherit !important;
  min-width: inherit;
}
.backbtn {
  display: inline-block;
  font-size: 23px;
  margin-right: 15px;
}

.feedback-wrap .job-listing-rows {
  background: transparent;
  padding: 0;
}
.like-dislike-btn {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
}
.like-dislike-btn svg path {
  fill: #8d8d8d;
}
.like-dislike-btn li.active svg path {
  fill: #3070cd;
}
.like-dislike-btn li {
  width: 86px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ececec;
  border-radius: 50%;
}
.interview-feedback .interview-list {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  border: 1px solid #ededed;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}
.inputbox-wrap .MuiFormControl-root {
  margin-top: 36px;
  width: 100%;
}
.inputbox-wrap .MuiFormControl-root .MuiInputBase-multiline {
  height: 170px;
}
.fcheckbox-holder {
  display: flex;
  align-items: center;
}

.fcheckbox-holder span {
  padding-left: 0;
}

.fcheckbox-holder label {
  font-size: 14px;
}

.fbutton-holder {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.fbutton-holder button.MuiButton-outlined {
  background: transparent;
  max-width: 146px;
  width: 100%;
}
.fcheckbox-holder label a {
  text-decoration: none;
  color: #1a8ac9;
}
.textarea-wrap label {
  display: block;
}

.textarea-wrap textarea {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 10px;
  height: 150px;
  margin-top: 5px;
}
.textarea-wrap button {
  background: #1a8ac9;
}

.interviewer-btn .btn {
  width: 100% !important;
  display: block !important;
}
.interviewer-btn {
  margin-bottom: 40px;
  margin-top: 40px;
}
a.sky-btn.btn {
  background: #1a8ac9;
}
a.border-btn.btn {
  background: transparent;
  border: 1px solid #8c9194;
  color: #8c9194;
}
a.red-btn.btn {
  background: #c50000;
}
.job-iterview {
  background: #f3f3f3;
}
.job-iterview .interviewer-name {
  display: block;
  color: #1a8ac9;
}
.job-iterview .job-posted {
  font-size: 14px;
}
.job-iterview ul.job-tech-stack {
  align-items: center;
}
.job-iterview .job-listing-stack {
  margin-bottom: 30px;
}
.videocall-modal .modal-header {
  display: block;
  text-align: center;
  position: relative;
}
.videocall-modal .checkbox-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0px 0px 20px 0px;
  font-size: 13px;
  flex-wrap: wrap;
}
.videocall-modal h4 {
  text-align: center;
  margin-bottom: 15px !important;
}
.videocall-modal button.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.videocall-modal .checkbox-holder .checkbox-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.video-holder video {
  width: 100%;
}
.video-holder {
  position: relative;
}
.video-holder .video-btn-holder {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
}
.video-btn {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: linear-gradient(180deg, #1a8ac9 0%, #4fc0ff 100%);
}
button.video-btn.cross {
  background: linear-gradient(180deg, #c91a1a 0%, #ff4f4f 100%);
}
.videocall-modal .modal-footer {
  justify-content: center;
}
.whiteboard-wrap .textarea-wrap .MuiFormControl-root {
  width: 100%;
  height: 700px;
}
.whiteboard-wrap .textarea-wrap .MuiFormControl-root .MuiInputBase-root {
  height: 100%;
  border-radius: 15px;
}
.whiteboard-wrap .textarea-wrap textarea {
  height: 100% !important;
  border: transparent;
}

.full-video-holder {
  position: fixed;
  top: 0;
  z-index: 9999;
  left: 0;
  right: 0;
  min-height: 100vh;
  width: 100%;
  background: #fff;
  text-align: center;
}
.full-video-holder .video-wraper {
  position: relative;
}
.full-video-holder video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: 0px;
}
.full-video-holder .video-btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.whiteboard-wrap .button-holder {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.whiteboard-wrap .button-holder button {
  max-width: 288px;
  width: 100%;
}
button.btn.sky {
  background: #1a8ac9;
}
button.btn.red {
  background: #c50000;
}
.interviewer-btn .MuiButton-root {
  padding: 18px 15px !important;
  color: #fff !important;
}
.interviewer-btn .MuiButtonBase-root.blue-btn {
  background: #031729;
}
.interviewer-btn .MuiButtonBase-root.sky-btn {
  background: #1a8ac9;
}
.interviewer-btn .MuiButton-root.red-btn {
  background: #c50000;
}
.interviewer-btn .MuiButton-root.border-btn {
  background: transparent;
  color: #8c9194 !important;
  border: 1px solid #8c9194;
}

.videocall-modal h4 {
  text-align: center;
}
.videocall-modal .checkbox-holder .radio-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.videocall-modal .checkbox-holder .radio-item label {
  font-size: 14px !important;
}
.videocall-modal .checkbox-holder .radio-item label span.MuiTypography-root.MuiTypography-body1 {
  font-size: 13px;
}

.dashboard-interview-wrap {
  padding: 100px 0px;
}
.editor-view .textarea-wrap section {
  border: 1px solid #eaeaea;
  padding: 30px;
}
.toggle-screen {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}

.full-video-holder.minimize-holder {
  width: 364px;
  height: 312px !important;
  top: inherit;
  bottom: 54%; /* height: auto; */
  min-height: auto;
  right: inherit;
  left: 30px;
  border-radius: 20px;
}
.full-video-holder.minimize-holder .video-wraper {
  padding-bottom: inherit;
  height: 100%;
}
.full-video-holder.minimize-holder .video-btn-holder {
  position: absolute;
  bottom: 22px;
  top: inherit;
  transform: inherit;
  left: 0;
  right: 0;
  padding-bottom: 0;
}

.full-video-holder.minimize-holder {
  width: 364px;
  height: 312px !important;
  top: inherit;
  bottom: 54%; /* height: auto; */
  min-height: auto;
  right: inherit;
  left: 30px;
  border-radius: 20px;
}
.full-video-holder.minimize-holder .video-wraper {
  padding-bottom: inherit;
  height: 100%;
}
.full-video-holder.minimize-holder .video-btn-holder {
  position: absolute;
  bottom: 22px;
  top: inherit;
  transform: inherit;
  left: 0;
  right: 0;
  padding-bottom: 0;
}
.infinite-scroll-component {
  row-gap: 10px;
}

.dashboard-content.editor-view {
  height: 100vh;
  padding-bottom: 0;
  padding-top: 30px;
  overflow: hidden;
}
.editor-view .whiteboard-wrap .textarea-wrap {
  height: 64vh;
}
.btn-wraps.interview-btn {
  justify-content: center;
  margin-top: 20px;
}
.video-holder.interview-video-wrap {
  padding-bottom: 65.25%;
}
.video-holder.interview-video-wrap video#videoplayer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px !important;
}
.video-btn-holder .MuiButtonBase-root.audio {
  background: linear-gradient(180deg, #1a8ac9 0%, #4fc0ff 100%);
  color: #fff !important;
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
}
.video-btn-holder .MuiButtonBase-root.video {
  background: linear-gradient(180deg, #1a8ac9 0%, #4fc0ff 100%);
  color: #fff !important;
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
}

.full-video-holder .video-wraper {
  position: relative;
  padding-bottom: 100vh;
}
.react-draggable.react-draggable-dragged {
  z-index: 999;
  position: relative;
}

.reason-wrap {
  width: 100%;
  display: block;
  max-width: 564px;
  background: #e9f7ff;
  border-radius: 10px;
  box-shadow: 4px 4px 25px 0px #0000000d;
}
.reason-wrap .top-holder {
  padding: 15px 30px;
  border-bottom: 1px solid #ffff;
}
.reason-wrap .top-holder h5 {
  font-size: 18px;
  color: #1a8ac9 !important;
  margin: 0;
}
.reason-wrap .body-holder {
  padding: 15px 30px;
}
.full-video-holder.minimize-holder video {
  border-radius: 20px;
}

.chat-user-profile .chat-msg-user {
  width: 34px;
  height: 34px;
  background: #eaeaea;
  border-radius: 50%;
}
.chat-user-profile .chat-msg-user img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 50%;
}
.chat-user-profile {
  margin-right: 5px;
  position: relative;
  top: 4px;
}
.chat_date_name {
  display: flex;
  align-items: center;
}
.chat_date_name .chat__name {
  margin: 0;
  font-size: 11px;
}
.chat_date_name span.chat_date {
  font-size: 11px;
  margin-left: 5px;
}
.upload-img_holder img {
  width: 160px !important;
  height: 160px !important;
  border-radius: 15px;
}
.upload-img_holder img {
  width: 160px !important;
  height: 160px !important;
  border-radius: 15px;
  object-fit: cover;
}
.upload-img_holder {
  position: relative;
  padding: 10px;
}

.upload-img_holder:after {
  content: "";
  position: absolute;
  padding: 0;
}
.upload-img_holder .cross-file {
  top: 15px;
  right: 15px;
  background: #ffffff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, 0.53);
}
.upload-img-pannel img {
  object-fit: cover;
  border-radius: 15px;
}
.chat-schedule-reason_wrap {
  width: -moz-fit-content;
  width: fit-content;
}
.chat-msg.schedule {
  padding: 0;
}

.interview-list__wrap {
  display: flex;
  align-items: center;
}
.interview-list__wrap .img-holder {
  margin-right: 10px;
}
.interview-feedback .interview-list_panel {
  border: 1px solid #ededed;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
}
.interview-feedback .interview-list_panel .img-holder {
  width: 45px;
  height: 45px;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
}

.interview-feedback .interview-list_panel .img-holder .MuiAvatar-root {
  height: 100%;
  width: 100%;
}
.interview-feedback .interview-list_panel .text-holder {
  padding-left: 30px;
  margin-top: 13px;
}
.interview-list__wrap h5 {
  margin-bottom: 0;
}

.chatimgmodal .modal-image {
  position: relative;
  padding-bottom: 82%;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #eaeaea;
}
.chatimgmodal .modal-image img {
  object-fit: contain;
  width: 98%;
  margin: auto;
}
.chat-sidebar .chat-contact-list__wrap {
  overflow-x: hidden !important;
}
/* .chat-msg-preview p {
  width: 209px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
} */
.chat-window img {
  object-fit: contain;
}

/*--------- Global Sanu CSS ----------*/
.dltplus_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.dltplus_wrap .unarchived-section.pointer {
  flex-grow: 1;
  width: auto;
  border-radius: 30px !important;
}

.dltplus_wrap button.btn {
  width: 30px;
  padding: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dltplus_wrap button.btn:hover {
  border: 1px solid #041628;
}
.archived-section p svg {
  font-size: 22px;
}

/*=============================== chat fixing css ==============*/
.chat-sidebar {
  padding: 10px;
}
.chat-sidebar .cus-tab button.MuiTab-root {
  font-size: 14px;
  padding: 5px;
}
.chat-sidebar .MuiTabs-flexContainer {
  width: 100%;
}
.unarchived-section {
  margin: 10px 0 10px;
  background: var(--dash-bg);
  border-radius: 30px;
  padding: 15px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unarchived-section p {
  font-size: 14px;
}
.chat-src-box {
  margin-top: 20px;
}
.chat-src-box form input {
  border: 1px solid #e0e0e0;
  padding: 12px 15px 12px 60px;
  border-radius: 50px !important;
  width: 100%;
  outline: none;
}
.chat-contact-list__wrap .chat-contact-list {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  position: relative;
}
.chat-contact-list__wrap .chat-contact-list span.time {
  font-size: 11px;
  position: absolute;
  top: 17px;
  right: 8px;
}
.chat-contact-list .chat-msg-count {
  background: var(--secondary);
  color: var(--white);
  font-size: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 20px;
  line-height: 1;
  position: absolute;
  bottom: 15px;
  right: 8px;
}
.chat-window-image .msg-user img {
  object-fit: cover;
}

/* chat video modal */
.video_popupmodal .common-modal-box {
  background: transparent;
  border: none;
  width: 95% !important;
  max-width: 100% !important;
  height: 95%;
}
.video_popupmodal .common-modal-box .modal-video {
  position: relative;
  width: auto;
  height: 100%;
}
.video_popupmodal .common-modal-box .modal-video video {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100% !important;
  object-fit: contain !important;
}
.modal-cross + div {
  height: 100%;
}
.video_popupmodal .modal-cross svg {
  fill: #fff;
  font-size: 29px;
}
/* chat video modal */

/* 08.01.2025*/
.user-menu img.post-img {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  border: 1px solid #bfbfbf;
}
.chat-input-area.personal-chat textarea.msg-bar {
  border-radius: 30px;
}
/* 08.01.2025*/

/*============== notification section style ==============*/
.interview-list.notification_pannel .img-holder > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}
.interview-list.notification_pannel .text-holder {
  width: 60%;
}
.interview-list.notification_pannel .button-holder {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 11px;
  justify-content: flex-end;
  padding-right: 4%;
}
.interview-list.notification_pannel .button-holder a.cross-icon {
  position: absolute;
  right: 5px;
  top: 0;
  color: #cccccc;
  font-size: 24px;
  display: block;
}
.interview-list.notification_pannel {
  padding: 25px;
}
.interview-list.notification_pannel a.cross-icon {
  position: absolute;
  right: 5px;
  top: 3px;
  color: #cccccc;
  font-size: 24px;
  display: none;
}

.interview-list.notification_pannel p {
  margin-bottom: 5px;
}
.interview-list.notification_pannel .posted_field {
  color: #7d7d7d;
  font-size: 12px;
}
.interview-list.notification_pannel h6 {
  margin-bottom: 5px;
}
.interview-list.notification_pannel .posted_field span,
.interview-list.notification_pannel .posted_field a {
  font-weight: 700;
  color: #1a8ac9;
  text-decoration: underline;
}
.interview-list.notification_pannel p.company {
  font-weight: 500;
  color: #1a8ac9;
  font-size: 14px;
}
.interview-list.notification_pannel span.date-field {
  font-size: 14px;
  color: #aaaaaa;
}

.interview-list.notification_pannel .button-holder button.btn:hover {
  border: 1px solid var(--primary);
}
.interview-list.notification_pannel button.cancel.btn {
  background: transparent;
  border: 1px solid #c50000;
  color: #c50000;
}
.interview-list.notification_pannel button.cancel.btn:hover {
  background: #c50000;
  color: #fff;
  border: 1px solid #c50000;
}
.interview-list.notification_pannel .location_field {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
/*============== notification section style ==============*/
/* .preview-file {
  position: relative;
  margin-bottom: 15px;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color2);
  background-color: var(--gray-light2);
  border-radius: var(--radius-10);
  padding: 15px;
  display: flex;
  align-items: center;
  min-height: 130px;
} */
.preview-file.chat-pdf {
  background: transparent !important;
  box-shadow: inherit !important;
  border: none !important;
  padding: 0 !important;
}
.preview-file ul.files-blocks-holder li.file-preview-block {
  display: flex;
  align-items: center;
}
.preview-file ul.files-blocks-holder li.file-preview-block .files-image-holder {
  width: 70px !important;
  height: 70px !important;
}
.preview-file ul.files-blocks-holder li.file-preview-block .files-image-holder .previewImage {
  width: 100%;
  height: 100%;
}
.preview-file ul.files-blocks-holder li.file-preview-block .files-image-holder .previewImage img {
  object-fit: contain !important;
}

.job-outer-holder .job-card-list {
  padding: 0;
  overflow-x: hidden;
  box-shadow: none;
  background: transparent;
}

.job-outer-holder .new-job-filter {
  margin: 0 !important;
}

.job-outer-holder .single-job-card {
  margin-bottom: 30px !important;
  margin-top: 0px !important;
}
.common-card-style.job-card-list.commonbox {
  background: var(--white);
  padding: 0px 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
}

.alignwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-infobox-single.active {
  border: 1px solid #082b53;
}

/* Scroll bar css (04.02.2025) */
body {
  overflow-x: hidden;
  overflow-y: auto;
}
.disable-outside-scroll .common-card-style {
  height: calc(100vh - 150px);
}
.disable-outside-scroll {
  overflow: hidden;
}
.job-card-list {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: calc(100vh - 150px);
  overflow-y: auto;
}
.job-details-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: calc(100vh - 150px);
  overflow-y: auto;
}
.interviewpannel .infinite-scroll-component {
  overflow: hidden !important;
  padding-bottom: 30px;
}
.common-card-style.profilepannel {
  overflow: visible;
  height: 100%;
}
.enable-outside-scroll .common-card-style.job-card-list {
  overflow: hidden;
}
.common-card-style {
  background: var(--white);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 1px 10px 30px rgb(0 0 0 / 3%);
  min-height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}

/* Scroll bar css (04.02.2025) */

/* ============================== media screen css ===========================*/

@media only screen and (max-width: 1799px) {
  :root {
    --radius-10: 6px !important;
    --radius-20: 15px !important;
    --radius-30: 20px !important;
  }

  body {
    font-size: 14px !important;
  }

  .h1,
  h1 {
    font-size: 30px !important;
  }

  .h5,
  h5 {
    font-size: 18px !important;
  }

  .h6,
  h6 {
    font-size: 14px !important;
  }

  .fz-30 {
    font-size: 24px !important;
  }

  .fz-25 {
    font-size: 22px !important;
  }

  .fz-18 {
    font-size: 16px !important;
  }

  .mb-40 {
    margin-bottom: 30px;
  }

  .nu-btn {
    min-height: 40px !important;
  }

  button.MuiButton-contained,
  button.MuiButton-text,
  button.MuiButton-outlined {
    font-size: 14px;
  }

  /*Auth*/
  .auth-ban-text {
    gap: 30px;
    bottom: 60px;
    left: 70px;
  }

  .auth-ban-text span {
    font-size: 70px;
    letter-spacing: -7px;
  }

  .auth-ban-text h1 {
    font-size: 80px !important;
    letter-spacing: -4px;
    max-width: 370px;
  }

  /*Auth*/

  /* Dashboard CSS */

  .feed-col-3 {
    margin: 0 -7px;
  }

  .feed-mid-col,
  .feed-common-col {
    padding: 0 7px;
  }

  .dashboard-wrap {
    padding: 15px 15px 0 265px;
    transition: padding 0.5s;
  }

  .dashboard-sidenav {
    height: calc(100vh - 15px);
    left: 15px;
    top: 15px;
    width: 230px;
    padding: 30px 15px 15px;
    transition: width 0.5s;
  }

  .dash-logo-wrap {
    margin-bottom: 30px;
    cursor: pointer;
  }

  .custom-sidenav-list {
    padding: 15px !important;
  }

  .custom-sidenav-list .sidenav-li {
    gap: 10px;
  }

  .custom-sub-menu ul {
    padding: 0 10px 10px;
    margin: 0;
    list-style: none;
  }

  .custom-sub-menu ul li {
    padding: 10px 10px;
    font-weight: var(--semi-bold);
  }

  .custom-sub-menu ul li:hover {
    border-radius: var(--radius-10);
  }

  .dash-top-nav {
    padding: 25px;
    top: 15px !important;
    margin-bottom: 10px;
  }

  .dash-top-nav h1 {
    margin-right: 20px;
  }

  .profile-text {
    padding: 10px 15px 20px;
  }

  .profile-cover-img {
    height: 125px;
    position: relative;
  }

  .profile-img img.dp {
    height: 90px;
    width: 90px;
    border: 4px solid var(--white);
    margin-top: -45px;
  }

  .user-menu {
    font-size: 16px;
    font-weight: var(--bold);
    color: var(--black);
    position: relative;
    padding-right: 20px;
  }

  .user-menu .user {
    width: 40px;
    height: 40px;
  }

  .notification .MuiBadge-root img {
    max-width: 20px;
  }

  .post-job-wrap button.MuiButton-contained,
  .post-job-wrap button.MuiButton-text {
    max-width: 200px;
    height: inherit;
  }

  .company-profile-component {
    padding: 10px 15px;
  }

  .company-imgs {
    width: 50px;
    height: 50px;
  }

  .company-links {
    gap: 10px;
    padding: 10px 0;
  }

  .comment-input-bar input {
    height: 50px;
    padding: 10px 115px 10px 15px;
    font-size: 12px;
  }

  .comment-input-btns {
    gap: 10px;
  }

  .comment-send {
    width: 40px !important;
    height: 40px !important;
  }

  .comment-avator {
    width: 40px !important;
    height: 40px !important;
  }

  .comment-wrap-reply {
    padding: 20px 0 0 50px;
  }

  .comment-user {
    width: 40px !important;
    height: 40px !important;
  }

  .feed-user {
    width: 45px !important;
    height: 45px !important;
  }

  .feed-post-content {
    padding: 20px 20px 20px;
  }

  .feed-apply-head button {
    max-width: 125px;
    width: 100%;
    padding: 8px 20px;
  }

  .feed-apply-wrap {
    padding: 35px 15px 15px;
  }

  .feed-apply-body .feed-apply-data label {
    padding-left: 10px;
  }

  .feed-comment-section {
    margin-top: 20px;
  }

  .feed-post-colum {
    gap: 6px;
  }

  .feed-post-colum span {
    font-size: 12px;
  }

  .feed-post-colum img {
    max-width: 15px;
  }

  .post-job-box {
    padding: 20px 15px;
    margin-bottom: 20px;
  }

  .post-job-box p {
    font-size: 16px;
    margin: 0px auto 15px;
  }

  .feed-notifications {
    padding: 20px 15px;
  }

  .notifications-content button.MuiButton-contained {
    padding: 10px 10px;
  }

  .notifications-content button.MuiButton-outlined {
    padding: 8px 10px;
  }

  .notifications-dp {
    width: 30px !important;
    height: 30px !important;
  }

  .notifications-content p {
    font-size: 12px;
  }

  span.notification-time {
    font-size: 12px;
  }

  .in-post {
    font-size: 12px;
  }

  .in-name {
    font-size: 10px;
  }

  .in-pby,
  .in-city {
    font-size: 11px;
  }

  .profile-component,
  .become-company,
  .company-profile-component,
  .feed-posts {
    margin-bottom: 20px;
  }

  .common-card-style {
    padding: 30px;
  }

  /* Dashboard CSS */
  .menu-close-icon {
    left: -35px;
    top: 20px;
  }

  .job-key-info-wrap ul li:not(:last-child) {
    margin-right: 0px;
  }

  .job-key-info-wrap ul li span::after {
    left: -5px;
  }

  .job-key-info-wrap ul li {
    padding-left: 10px;
  }

  /* ========= Edit Profile CSS ============== */
  .edit-profile-bookmark {
    top: 120px;
  }

  .edit-profile-bookmark ul li {
    padding: 10px;
  }

  .edit-profile-bookmark ul li p {
    font-size: 16px;
  }

  /* ========= Edit Profile CSS ============== */

  /* ========= Pradipta 1599 ============== */
  .mb-30 {
    margin-bottom: 20px;
  }

  .th-left-sidebar {
    max-width: 240px;
    margin-right: 0px;
    padding: 10px;
    border-radius: 15px;
    top: 115px;
  }

  .th-left-sidebar ul li {
    padding: 10px 5px;
    cursor: pointer;
    position: relative;
  }

  .th-left-sidebar ul li:after {
    right: 5px;
  }

  .th-right-wrap-sec {
    width: calc(100% - 270px);
    padding: 20px;
  }

  .pay-gateway-box {
    padding: 15px;
  }

  button.light-blue-btn,
  button.light-blue-btn.red {
    padding: 5px 8px;
    font-size: 10px !important;
    min-width: inherit;
  }

  .receipt-box {
    padding: 20px;
  }

  .job-listing-rows {
    padding: 20px;
  }

  ul.job-tech-stack {
    gap: 15px;
  }

  .job-listing-info ul {
    row-gap: 20px;
  }

  .job-listing-collps-btn {
    margin: 10px 0 -12px;
  }

  .pay-mods {
    margin-left: 20px;
    max-width: 230px;
  }

  .payment-detils-side h5 {
    font-size: 16px !important;
  }

  .pay-icon img {
    width: 50px;
    height: 50px;
  }

  .pay-table-subtotal {
    margin-bottom: 8px;
  }

  .chat-contacts {
    height: calc(100vh - 244px);
    margin-right: -12px;
  }

  .chat-window-header {
    padding: 10px 25px 10px;
  }

  .chat-window-header:after {
    left: 25px;
    right: 25px;
  }

  .chat-msg {
    padding: 0 25px 10px;
  }
  .infinite-scroll-component {
    row-gap: 5px;
  }
  .chat-contact-list {
    gap: 10px;
    padding: 12px 10px 12px 10px;
  }

  .chat-msg-preview .chat-msg-count,
  .archived-count {
    font-size: 9px;
    width: 20px;
    height: 20px;
  }

  .archived-section {
    margin: 15px 0 10px;
    border-radius: 10px;
    padding: 15px 16px;
  }

  .chat-src-box {
    margin-top: 10px;
  }

  .chat-src-box form input {
    padding: 11px 12px 11px 50px;
  }

  .chat-src-box form button {
    left: 20px;
    transform: translateY(-50%) scale(0.9);
  }

  .chat-msg-preview p {
    width: 180px;
  }

  .common-card-style.chat-sidebar {
    height: calc(100vh - 140px);
  }

  .chat-window {
    height: calc(100vh - 140px);
  }

  .chat-window-body {
    height: calc(100% - 142px);
  }

  .schedule-btn {
    max-width: 145px;
  }

  .chat-input-area textarea.msg-bar {
    padding: 20px 55px 20px 53px;
    min-height: auto;
  }

  .msg-send-btn {
    width: 40px !important;
    height: 40px;
    right: 10px;
  }

  .msg-attech {
    left: 15px;
  }

  .chat-input-area:after {
    left: 44px;
  }

  .chat-sidebar .cus-tab button.MuiTab-root {
    margin-right: 30px;
    padding: 8px 0;
    min-height: inherit;
  }

  .chat-sidebar .MuiTabs-root {
    min-height: inherit;
  }

  .job-bottom-flex button {
    min-width: 120px;
  }

  /* ========= Pradipta 1599 ============== */

  .job-key-info-wrap ul li:not(:last-child) {
    margin-right: 10px;
  }

  .job-key-info-wrap ul li {
    width: calc(25% - 10px);
  }
  .chat-sidebar-wrap {
    width: 30% !important;
  }
  .chat-window-wrap {
    width: 70% !important;
  }
  .chat-sidebar-wrap .common-card-style.chat-sidebar {
    padding: 12px;
  }

  .main-tab .chat-contacts {
    height: calc(100vh - 326px);
  }
  .chat-user-name-time h6 {
    width: 190px;
  }
  .chat-sidebar-wrap .form-group {
    margin: 0;
  }

  .dashboard-content .chat-sidebar-wrap {
    width: 35% !important;
  }
  .dashboard-content .chat-window-wrap {
    width: 65% !important;
  }

  .interview-list.notification_pannel .text-holder {
    width: 56%;
  }
  .interview-list.notification_pannel .button-holder button.btn {
    font-size: 14px;
    padding: 10px 33px;
  }
}

@media only screen and (max-width: 1599px) {
  .chat-sidebar-wrap {
    width: 35% !important;
  }
  .chat-window-wrap {
    width: 65% !important;
  }
  .chat-user-name-time h6 {
    width: 140px;
    font-size: 12px !important;
  }
  .chat-msg-preview p {
    width: 150px;
    font-size: 11px;
  }
  .chat-user-img {
    min-width: 36px;
    max-width: 36px;
    height: 36px;
  }
  .interview-list.notification_pannel .text-holder {
    width: 50%;
  }
}

@media only screen and (max-width: 1359px) {
  /* ========= Pradipta 1359 ============== */
  .th-left-sidebar {
    max-width: 195px;
  }

  .th-right-wrap-sec {
    width: calc(100% - 215px);
    padding: 20px;
  }

  img.pay-img {
    max-width: 40px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .pay-gateway-box h6 {
    margin-bottom: 10px;
    font-size: 12px !important;
  }

  .pay-save-card {
    gap: 10px;
    margin-bottom: 10px;
  }

  .pay-save-card label {
    font-size: 9px;
  }

  .pay-save-card span {
    font-size: 12px;
  }

  .pay-gateway-box {
    padding: 10px;
  }

  .pay-gateway-btns {
    gap: 8px;
  }

  button.light-blue-btn,
  button.light-blue-btn.red {
    padding: 4px 8px;
  }

  .th-table-box .table thead th {
    padding: 10px 10px 0 !important;
  }

  .th-table-box .table thead th:first-child {
    padding-left: 10px !important;
  }

  .th-table-box .table tbody td:first-child {
    padding-left: 10px;
  }

  .th-table-box .table tbody td {
    padding: 10px 10px;
  }

  .th-table-box .table * {
    font-size: 11px;
  }

  .job-infobox-header.job-infobox-top-hdr::after {
    right: -10px;
  }

  ul.job-tech-stack img {
    max-width: 44px;
  }

  ul.job-tech-stack p {
    margin: 0px 0 0;
    font-size: 12px;
  }

  .job-listing-user-img .MuiAvatar-root {
    width: 50px;
    height: 50px;
  }

  .job-listing-user {
    gap: 10px;
  }

  .btn-wraps button {
    min-width: inherit;
    width: 100%;
    max-width: 155px;
  }

  .schedule-btn {
    max-width: 105px;
  }

  .common-card-style.chat-sidebar {
    padding: 15px 8px;
  }

  .archived-section p {
    gap: 8px;
  }

  .archived-section p svg {
    font-size: 22px;
  }

  .chat-user-name-time {
    margin-bottom: 4px;
  }

  .chat-msg-text {
    padding: 12px 20px 12px 15px;
    border-radius: 15px 25px 25px 0;
  }

  .owner .chat-msg-text {
    border-radius: 25px 0 15px 25px;
    padding: 12px 15px 12px 20px;
  }

  .job-bottom-flex {
    align-items: flex-end;
  }
  .chat-sidebar-wrap {
    width: 40% !important;
  }
  .chat-window-wrap {
    width: 60% !important;
  }
  .interview-list.notification_pannel .button-holder button.btn {
    font-size: 13px;
    padding: 10px 27px;
  }
  .interview-list.notification_pannel .text-holder {
    width: 45%;
  }
  /* ========= Pradipta 1359 ============== */
}

@media only screen and (max-width: 1240px) {
  .notifications-content button.MuiButton-contained,
  .notifications-content button.MuiButton-outlined {
    font-size: 10px;
  }

  .post-job-wrap button.MuiButton-contained,
  .post-job-wrap button.MuiButton-text {
    max-width: 170px;
  }

  button.MuiButton-contained,
  button.MuiButton-text,
  button.MuiButton-outlined {
    font-size: 12px;
  }

  .common-modal-box {
    max-width: 660px !important;
  }
}

@media only screen and (max-width: 1199px) {
  /*Auth*/
  .auth-section .row {
    flex-direction: column-reverse;
  }

  .auth-banner {
    height: 400px;
  }

  .auth-form-left {
    height: auto;
    padding: 30px 15px 50px !important;
  }

  .auth-ban-img {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: var(--radius-20);
  }

  .auth-ban-img img {
    object-position: center;
  }

  .auth-ban-text {
    gap: 0;
    bottom: 50px;
    left: 20px;
    right: 20px;
    flex-direction: column;
    text-align: center;
  }

  .auth-ban-text span {
    font-size: 50px;
    letter-spacing: -2px;
  }

  .auth-ban-text h1 {
    font-size: 60px !important;
    letter-spacing: -2px;
    max-width: inherit;
  }

  /*Auth*/

  /* Dashboard CSS */

  .dashboard-sidenav {
    left: -240px;
    transition: width 0.5s;
  }

  .dashboard-wrap {
    padding: 15px 15px 0 15px;
    transition: padding 0.5s;
  }

  /* Dashboard CSS */

  .menu-close-icon {
    display: none;
  }

  .hammenuBtn {
    display: block !important;
  }

  .banner-section {
    padding: 136px 0 60px;
  }

  .about-section {
    padding: 50px 0 60px;
  }

  .common-padding {
    padding: 60px 0;
  }

  .users-img {
    max-width: 440px;
  }

  .audience-img img {
    height: auto;
  }

  /* ========= Edit Profile CSS ============== */
  .edit-profile-bookmark {
    max-width: 220px;
  }

  .edit-profile-details {
    width: calc(100% - 250px);
    margin-left: 240px;
  }

  /* ========= Edit Profile CSS ============== */

  /* ========= Pradipta 1199 ============== */
  .cus-slider-width {
    width: 100% !important;
    padding: 0 5px;
  }

  /* ========= Pradipta 1199 ============== */
  .chat-sidebar-wrap {
    width: 40% !important;
  }
  .chat-window-wrap {
    width: 60% !important;
  }
}

@media only screen and (max-width: 991px) {
  :root {
    --radius-20: 10px !important;
  }

  .h1,
  h1 {
    font-size: 30px !important;
  }

  /* Dashboard CSS */
  .feed-common-col {
    flex: 0 0 auto;
    width: 35%;
  }

  .notifications-content {
    width: calc(100% - 40px);
  }

  .feed-common-col.notification-col {
    width: 65%;
    margin-left: 35%;
  }

  .notifications-content button.MuiButton-contained,
  .notifications-content button.MuiButton-outlined {
    max-width: 160px;
    display: block;
  }

  .dash-top-nav {
    padding: 15px;
  }

  .user-menu {
    font-size: 14px;
    gap: 10px;
  }

  .user-menu .user {
    width: 30px;
    height: 30px;
  }

  .common-modal-box {
    padding: 20px !important;
    width: 90%;
  }

  /* Dashboard CSS */

  .about-box a.btn {
    margin: 0 auto -10px;
  }

  .usages-box {
    display: block;
    text-align: center;
  }

  .usages-box p {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    margin-top: 20px;
  }

  .users-img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .users-tab {
    margin-bottom: 20px;
  }

  .users-text h2 {
    margin-bottom: 20px;
  }

  .users-img img {
    height: auto;
  }

  /* ========= Edit Profile CSS ============== */

  /* ========= Edit Profile CSS ============== */

  /* ========= Pradipta 991 ============== */
  .th-left-sidebar {
    top: 85px;
  }

  .th-table-box table {
    min-width: 620px;
  }

  .receipt-table tbody tr td {
    padding: 10px 5px;
  }

  .receipt-table tfoot tr th {
    padding: 8px 5px;
  }

  .receipt-table thead tr th {
    padding: 6px 5px;
  }

  .receipt-table tfoot tr th {
    font-size: 13px;
  }

  .receipt-head h6,
  .receipt-head p {
    font-size: 12px !important;
  }

  .bill-details h6 {
    font-size: 13px !important;
  }

  .receipt-head {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .receipt-box {
    padding: 15px;
  }

  .receipt-btns button {
    min-width: inherit;
    padding: 8px 25px;
  }

  .job-listing-info ul {
    row-gap: 20px;
    max-width: 100%;
    margin-left: 0;
  }

  .job-listing-info ul li {
    flex-basis: 33.33%;
  }

  .job-listing-end {
    max-width: 100%;
    margin-left: 0 !important;
  }

  .job-listing-end {
    max-width: 100%;
    margin-left: 0 !important;
  }

  .job-listing-end > div {
    padding: 0;
  }

  .job-listing-additional-qst ol {
    font-size: 12px;
  }

  .job-listing-additional-qst {
    margin: 20px 0 0;
    padding: 15px;
  }

  ul.job-tech-stack img {
    max-width: 50px;
  }

  ul.job-tech-stack {
    gap: 10px;
  }

  ul.job-tech-stack p {
    font-size: 14px;
  }

  .job-key-info-wrap ul {
    margin-top: 15px;
  }

  .job-listing-icons button {
    width: 40px;
    padding: 12px !important;
    height: 40px;
  }

  .job-listing-icons button img {
    width: 100%;
    height: auto;
  }

  .chat-window-header {
    padding: 20px 20px 15px;
  }

  .chat-window-header:after {
    left: 20px;
    right: 20px;
  }

  .chat-msg {
    padding: 0 20px 20px;
  }

  .chat-schedule-box {
    max-width: 300px;
  }

  .chat-schedule-box h5 {
    padding: 12px;
  }

  .chat-schedule-data {
    padding: 10px 10px;
    font-size: 12px;
  }

  .schedule-btns button {
    width: 48%;
    font-size: 12px !important;
    min-width: inherit;
    min-height: inherit !important;
  }

  /* ========= Pradipta 991 ============== */
  .apply-job-header-card .job-detls-header {
    flex-direction: column;
  }

  .apply-job-header-card .job-infobox-header {
    border-right: 0;
    margin-bottom: 20px;
  }

  .apply-job-header-card .job-details-holder {
    padding-left: 0;
  }

  .video-btn-holder .MuiButtonBase-root.audio {
    width: 35px;
    height: 35px;
  }
  .video-btn-holder .MuiButtonBase-root.video {
    width: 35px;
    height: 35px;
  }
  .video-holder .video-btn-holder {
    bottom: 10px;
  }
  .video-holder.interview-video-wrap {
    margin-bottom: 30px;
  }
  .chat-user-name-time h6 {
    width: 96px;
    font-size: 12px !important;
  }
  .chat-msg-preview p {
    width: 130px;
    font-size: 11px;
  }
  .chat-contact-list__wrap .chat-contact-list {
    padding: 11px 6px;
  }
  .common-card-style.chat-sidebar {
    height: calc(100vh - 116px);
  }
  .chat-window {
    height: calc(100vh - 116px);
  }
  .dashboard-content .chat-sidebar-wrap {
    width: 40% !important;
  }
  .dashboard-content .chat-window-wrap {
    width: 60% !important;
  }
  .chat-window-body {
    height: calc(100% - 154px);
  }
  .interview-list.notification_pannel {
    display: block;
  }
  .interview-list.notification_pannel .button-holder {
    justify-content: flex-start;
    padding-right: 0;
    margin-top: 13px;
  }
  .interview-list.notification_pannel .button-holder a.cross-icon {
    display: none;
  }
  .interview-list.notification_pannel a.cross-icon {
    display: block;
  }
  .interview-list.notification_pannel .text-holder {
    width: 100%;
    margin-top: 15px;
  }
  .interview-list.notification_pannel .button-holder {
    flex-wrap: wrap;
    row-gap: 15px;
  }
  .disable-outside-scroll .common-card-style {
    height: calc(100vh - 116px);
  }
  .edit-profile-bookmark {
    margin-right: 20px;
    top: 107px;
  }
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 14px !important;
  }

  .h1,
  h1 {
    font-size: 25px !important;
  }

  .h4,
  h4 {
    font-size: 20px !important;
  }

  .h5,
  h5 {
    font-size: 16px !important;
  }

  .fz-18 {
    font-size: 14px !important;
  }

  .fz-25 {
    font-size: 20px !important;
  }

  .fz-30 {
    font-size: 20px !important;
  }

  .mb-25 {
    margin-bottom: 20px;
  }

  /*Common Css*/
  button.MuiButton-contained,
  button.MuiButton-text,
  button.MuiButton-outlined {
    padding: 10px 10px;
  }

  /*Common Css*/

  /*Auth*/
  .auth-heading h2,
  h2 {
    font-size: 30px !important;
  }

  .auth-ban-text span {
    font-size: 30px;
    letter-spacing: 0;
  }

  .auth-ban-text h1 {
    font-size: 40px !important;
    letter-spacing: -1px;
    max-width: inherit;
  }

  /*Auth*/

  .src-box {
    display: none;
  }

  .topnav-right {
    margin-left: auto;
  }

  /* Dashboard CSS */
  .dash-top-nav {
    border-radius: var(--radius-10);
  }

  .dash-top-nav h1 {
    margin-right: 10px;
  }

  .dashboard-wrap {
    padding: 10px 10px 0 10px;
    transition: padding 0.5s;
  }

  .feed-common-col {
    flex: 0 0 auto;
    width: 100%;
  }

  .feed-common-col.notification-col {
    width: 100%;
    margin-left: 0;
  }

  .profile-text button.MuiButton-contained {
    width: 90%;
    max-width: 200px;
  }

  .become-company button.MuiButton-contained {
    width: 90%;
    max-width: 200px;
  }

  .custom-menu .MuiMenu-paper {
    max-width: inherit !important;
    min-width: inherit !important;
    width: 92%;
    right: 15px !important;
    left: 15px !important;
    padding: 5px 15px;
  }

  .post-job-box p {
    font-size: 14px;
  }

  .company-texts h5 {
    margin: 0 0 3px;
  }

  .feed-post-content {
    padding: 20px 10px 20px;
  }

  .feed-post-links {
    gap: 10px;
  }

  .feed-link-texts p,
  .feed-link-texts span {
    font-size: 12px;
  }

  .feed-apply-wrap {
    padding: 30px 10px 12px;
  }

  .feed-post-colum {
    gap: 4px;
  }

  .feed-post-colum:not(:last-child) {
    border-right: none;
  }

  .feed-post-colum img {
    max-width: 10px;
  }

  .feed-post-colum span {
    font-size: 10px;
  }

  .feed-apply-body {
    margin-top: 10px;
    row-gap: 5px;
  }

  .feed-apply-body .feed-apply-data {
    width: 50%;
  }

  .feed-apply-body .feed-apply-data label {
    padding-left: 5px;
    border-left: 1px solid var(--secondary);
  }

  .feed-apply-body .feed-apply-data p {
    padding-left: 5px;
  }

  .feed-apply-head button {
    max-width: 70px;
    width: 100%;
    padding: 6px 10px;
  }

  .comment-input-bar input {
    padding: 10px 104px 10px 15px;
  }

  .comment-input-btns {
    gap: 5px;
  }

  .comment-wrap-reply {
    padding: 20px 0 0 30px;
  }

  .common-card-style {
    padding: 24px 15px;
  }

  .btn-wraps {
    margin: 0 !important;
  }

  .btn-wraps button.MuiButton-contained,
  .btn-wraps button.MuiButton-text,
  .btn-wraps button.MuiButton-outlined {
    display: block;
    width: 100% !important;
    min-width: inherit;
    margin: 0 0 10px;
  }

  .account-form-wrap {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .account-set-heading p {
    margin: 0 0 24px;
  }

  .modal-cross {
    right: 5px;
    top: 5px;
  }

  /* Dashboard CSS */
  .foot-btn {
    display: block;
    text-align: center;
    margin: 20px 0 0 0 !important;
    padding: 13px 45px;
    font-size: 15px;
  }

  footer .ftr-logo,
  .ftr-btm {
    text-align: center;
  }

  .ftr-btm-link ul {
    justify-content: center;
    margin-top: 8px;
  }

  header .ftr-logo {
    width: 91px;
  }

  header .btn {
    padding: 12px 25px;
  }

  .contact-form {
    padding: 30px;
  }

  /* ========= Edit Profile CSS ============== */

  .edit-profile-details {
    width: 100%;
    margin-left: 0;
  }

  .edit-profile-heading {
    margin-bottom: 20px;
  }

  .checkbox-heading {
    margin-bottom: 20px;
  }

  .edit-profile-heading button.MuiButton-contained {
    min-width: 140px;
  }

  .edit-form-sec {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .edit-profile-bookmark {
    max-width: inherit;
    border-radius: 10px;
    width: 100%;
    z-index: 2 !important;
    margin-right: 0;
    margin-bottom: 15px;
    float: none;
    overflow: auto;
    top: 95px;
    padding: 20px 10px;
  }

  .edit-profile-bookmark ul {
    display: flex;
    align-items: center;
    width: auto;
    white-space: nowrap;
  }

  .edit-profile-bookmark ul li {
    padding: 0 10px;
  }

  .edit-profile-bookmark ul li:not(:last-child) {
    border: 0;
  }

  .topnav-right {
    gap: 10px;
  }

  .user-menu {
    gap: 5px;
    padding-left: 15px;
    padding-right: 5px;
  }

  /* ========= Edit Profile CSS ============== */

  /* ========= OTP CSS ============== */

  .otp-group > div {
    width: 100%;
    justify-content: center;
    gap: 0 !important;
  }

  .otp-group input[type="number"] {
    width: 45px !important;
    height: 45px !important;
    font-size: 16px !important;
    padding: 5px !important;
  }

  /* ========= Edit Profile CSS ============== */

  /* ========= Pradipta 767 ============== */
  .fz-14 {
    font-size: 12px !important;
  }

  .th-left-sidebar {
    max-width: inherit;
    position: inherit;
    float: none;
    margin-bottom: 19px;
  }

  .th-right-wrap-sec {
    width: inherit;
    padding: 20px;
  }

  button.light-blue-btn,
  button.light-blue-btn.red {
    padding: 4px 8px;
  }

  .mb-20 {
    margin-bottom: 10px;
  }

  .th-table-box .table * {
    font-size: 12px !important;
  }

  .th-table-box table {
    min-width: 670px;
  }

  .receipt-head {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .bill-details h6 {
    margin-bottom: 5px;
  }

  .receipt-table.table {
    min-width: 320px;
  }

  .receipt-btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .receipt-btns button {
    margin: 0;
    width: 100%;
  }

  .job-listing-btn button {
    width: 100%;
    max-width: inherit;
    margin: 0 0 10px;
  }

  .job-listing-info ul li {
    flex-basis: 100%;
  }

  .job-listing-udetails h6,
  .job-listing-udetails .job-listing-add {
    word-break: break-all;
  }

  .job-listing-user-img .MuiCheckbox-root {
    padding: 3px 0 0 0;
  }

  .job-short-desc {
    padding-right: 0px;
  }

  .job-infobox-header.job-infobox-top-hdr::after {
    display: none;
  }

  .job-listing-user-img {
    display: flex;
    flex-direction: column-reverse;
  }

  .job-listing-rows {
    padding: 15px 10px;
    border-radius: 10px;
  }

  .job-listing-udetails .job-listing-match {
    display: block;
    margin: 4px 0 0;
  }

  .job-listing-udetails .job-listing-add {
    font-size: 12px;
  }

  .job-listing-collps-btn {
    margin: 0px 0 -5px;
  }

  .job-form-btns {
    display: flex;
    gap: 2%;
  }

  .job-form-btns button {
    width: 49%;
    min-width: inherit;
    margin: 0;
  }

  .job-publish-btn button {
    width: 100%;
    min-width: inherit;
  }

  .payment-dtls-right {
    margin-bottom: 15px;
  }

  .btn-wraps {
    flex-direction: column;
  }

  .btn-wraps button {
    max-width: inherit;
  }

  .pay-mods {
    margin: 10px 0 0;
    max-width: 250px;
  }

  .payment-detils-side button {
    min-width: inherit;
    width: 100%;
  }

  .common-card-style.chat-sidebar {
    padding: 15px 15px;
    margin-bottom: 0;
    height: calc(100vh - 110px);
  }

  .archived-section {
    padding: 15px 12px;
  }

  .chat-window-header {
    padding: 10px;
  }

  .chat-window-header:after {
    left: 10px;
    right: 10px;
  }

  .chat-msg {
    padding: 0 10px 10px;
  }

  .chat-window-user {
    gap: 5px;
  }

  .chat-window-image .msg-user {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
  }

  .conversation-date {
    font-size: 10px;
    padding: 3px 10px;
  }

  .chat-msg-text {
    padding: 10px 15px 10px 12px;
    border-radius: 10px 20px 20px 0;
    font-size: 12px;
  }

  .owner .chat-msg-text {
    border-radius: 20px 0 10px 20px;
    padding: 10px 12px 10px 15px;
  }

  .conversation-date {
    margin: 0 auto 20px;
  }

  .chat-msg-content {
    max-width: 80%;
  }

  .chat-window-body {
    height: calc(100% - 100px);
  }

  .chat-window-footer {
    flex-direction: column;
    border-radius: 0;
  }

  .msg-attech {
    left: 8px;
  }

  .chat-input-area:after {
    height: 16px;
    left: 35px;
  }

  .chat-input-area textarea.msg-bar {
    padding: 10px 50px 10px 40px;
    min-height: 50px;
  }

  .msg-send-btn {
    width: 35px !important;
    height: 35px;
    right: 6px;
    top: 50%;
  }

  .schedule-btn {
    max-width: inherit;
    border-radius: 0 !important;
  }

  .dashboard-content {
    padding-bottom: 10px;
  }

  .chat-window {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
    transition: 300ms ease-in-out;
    transform: translateY(110%);
    border-radius: 0;
  }

  .chat-contacts {
    height: calc(100vh - 324px);
    margin-right: -5px;
  }

  .chat-window.chat-is-open {
    transform: translateY(0%);
  }

  .dashboard-content .row {
    margin: 0 -10px;
  }

  .back-to-chat-menu {
    display: block;
    margin-left: -4px;
  }

  .chat-schedule-box {
    max-width: 280px;
  }

  .job-bottom-flex {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .new-job-filter .filter-input-wrap {
    gap: 10px;
    flex-direction: column;
  }

  .new-job-filter .filter-input-wrap input {
    padding: 15px 20px;
  }

  .new-job-filter .filter-input-wrap button.MuiButton-text {
    font-size: 16px !important;
    width: 100%;
    min-width: inherit;
    padding: 15px !important;
  }

  ul.filter-sugg-tag {
    gap: 5px;
    margin: 10px 0 0;
  }

  .job-infobox-header .mj-match {
    margin: 5px 0 0;
    display: block;
  }

  /* ========= Pradipta 767 ============== */

  .location-dir-holder {
    flex-direction: column;
  }

  .loc-dir-inner {
    width: 100%;
  }

  .close-job-checkboxes {
    margin-left: 15px;
  }

  .candidate-table-row {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
  }

  .table-header-row {
    display: none;
  }
  .job-infobox-form.short-holder .row {
    row-gap: 20px;
  }
  .chat-sidebar-wrap {
    width: 100% !important;
  }
  .main-tab .chat-contacts {
    height: calc(100vh - 275px);
  }
  .chat-contacts {
    height: calc(100vh - 214px);
    margin-right: -5px;
  }
  .dashboard-content .chat-sidebar-wrap {
    width: 100% !important;
  }
  .dashboard-content .chat-window-wrap {
    width: 100% !important;
  }
  .disable-outside-scroll .edit-profile-details {
    height: calc(100vh - 201px);
    min-height: auto;
  }
}

@media only screen and (max-width: 360px) {
  .otp-group input[type="number"] {
    width: 40px !important;
    height: 40px !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* *::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* * {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* For Side bar handling */
:root[sidenav-status="close"] {
  @media only screen and (min-width: 1199px) {
    .dashboard-sidenav {
      width: 60px;
      transition: width 0.5s;
      padding: 40px 11px 20px;
    }

    .dashboard-wrap {
      padding: 15px 15px 0 112px;
      transition: padding 0.5s;
    }

    .menu-close-icon {
      transform: rotate(180deg);
    }

    .sidenav-li span {
      display: none;
    }

    .custom-sidenav .nav-arrow {
      display: none;
    }

    .custom-sidenav-list {
      padding: 7px !important;
    }

    .menu-close-icon {
      position: absolute;
      left: -29px !important;
      top: 8px;
    }

    .custom-sidenav-list .MuiAccordionSummary-content {
      justify-content: center;
    }
  }
}

.show-more-btn {
  margin-left: 0 !important;
  margin-top: 20px !important;
}

.selected-skill {
  background-color: var(--secondary);
  color: white;
  padding: 5px;
  cursor: pointer;
  margin: 3px 0;
  border-radius: 5px;
}

.non-selected-skill {
  padding: 5px;
  cursor: pointer;
  margin: 3px 0;
  border-radius: 5px;
}

.auto-complete-search fieldset {
  border-radius: 18px;
  border-color: var(--border-color2) !important;
}

.none {
  display: none !important;
}

.files-blocks-holder li {
  margin-bottom: 15px;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color2);
  background-color: var(--gray-light2);
  border-radius: var(--radius-10);
  padding: 10px;
  display: flex;
  align-items: center;
  min-height: 130px;
  position: relative;
  width: 100%;
  max-width: 580px;
}
.preview-file .files-blocks-holder li.file-preview-block {
  width: 100%;
  max-width: 100%;
}

.preview-file .delete-icon {
  position: absolute;
  top: -10px !important;
  right: -10px;
  border-radius: 50%;
  padding: 5px;
}

.previewImage img {
  /* Set the height of the image */
  height: 100px;
  /* You can adjust this value as needed */

  /* Set the width of the image */
  width: auto;
  /* This will maintain the aspect ratio of the image */
}

.preview-file .delete-icon svg {
  color: white;
}

.main-loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 999;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--secondary);
  color: var(--secondary);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -30px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--secondary);
  color: var(--secondary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 30px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--secondary);
  color: var(--secondary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--secondary);
  }

  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.error-msg {
  color: var(--red);
  margin: 0;
  margin-top: 6px;
  margin-left: 10px;
  font-size: 12px;
}

.MuiSelect-select {
  height: 1.4375em !important;
}

.active-icon {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #00b428;
  margin-left: 10px;
}

.update-info-account {
  display: flex;
  align-items: center;
  gap: 20px;
}

.update-info-account svg {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

/* Faq related doc css */

@page Section1 {
  size: 8.5in 11in;
  margin: 1in;
  mso-header-margin: 0.5in;
  mso-footer-margin: 0.5in;
  mso-paper-source: 0;
}

table {
  border-collapse: collapse;
}

table td,
table th {
  padding: 5px;
}

td {
  page-break-inside: avoid;
}

tr {
  page-break-after: avoid;
}

div.Section1 {
  page: Section1;
}

/* Confluence print stylesheet. Common to all themes for print medi=
a */
/* Full of !important until we improve batching for print CSS */

@media print {
  #main {
    padding-bottom: 1em !important;
    /* The default padding of 6em is to=
o much for printouts */
  }

  body {
    font-family: Arial, Helvetica, FreeSans, sans-serif;
    font-size: 10pt;
    line-height: 1.2;
  }

  body,
  #full-height-container,
  #main,
  #page,
  #content,
  .has-personal-sidebar #content {
    background: var(--ds-surface, #fff) !important;
    color: var(--ds-text, #000) !important;
    border: 0 !important;
    width: 100% !important;
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    display: block !important;
  }

  a,
  a:link,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    color: var(--ds-text, #000);
  }

  #content h1,
  #content h2,
  #content h3,
  #content h4,
  #content h5,
  #content h6 {
    font-family: Arial, Helvetica, FreeSans, sans-serif;
    page-break-after: avoid;
  }

  pre {
    font-family: Monaco, "Courier New", monospace;
  }

  #header,
  .aui-header-inner,
  #navigation,
  #sidebar,
  .sidebar,
  #personal-info-sidebar,
  .ia-fixed-sidebar,
  .page-actions,
  .navmenu,
  .ajs-menu-bar,
  .noprint,
  .inline-control-link,
  .inline-control-link a,
  a.show-labels-editor,
  .global-comment-actions,
  .comment-actions,
  .quick-comment-container,
  #addcomment {
    display: none !important;
  }

  /* CONF-28544 cannot print multiple pages in IE */
  #splitter-content {
    position: relative !important;
  }

  .comment .date::before {
    content: none !important;
    /* remove middot for print view */
  }

  h1.pagetitle img {
    height: auto;
    width: auto;
  }

  .print-only {
    display: block;
  }

  #footer {
    position: relative !important;
    /* CONF-17506 Place the footer at en=
d of the content */
    margin: 0;
    padding: 0;
    background: none;
    clear: both;
  }

  #poweredby {
    border-top: none;
    background: none;
  }

  #poweredby li.print-only {
    display: list-item;
    font-style: italic;
  }

  #poweredby li.noprint {
    display: none;
  }

  /* no width controls in print */
  .wiki-content .table-wrap,
  .wiki-content p,
  .panel .codeContent,
  .panel .codeContent pre,
  .image-wrap {
    overflow: visible !important;
  }

  /* TODO - should this work? */
  #children-section,
  #comments-section .comment,
  #comments-section .comment .comment-body,
  #comments-section .comment .comment-content,
  #comments-section .comment p {
    page-break-inside: avoid;
  }

  #page-children a {
    text-decoration: none;
  }

  /**
     hide twixies

     the specificity here is a hack because print styles
     are getting loaded before the base styles. */
  #comments-section.pageSection .section-header,
  #comments-section.pageSection .section-title,
  #children-section.pageSection .section-header,
  #children-section.pageSection .section-title,
  .children-show-hide {
    padding-left: 0;
    margin-left: 0;
  }

  .children-show-hide.icon {
    display: none;
  }

  /* personal sidebar */
  .has-personal-sidebar #content {
    margin-right: 0px;
  }

  .has-personal-sidebar #content .pageSection {
    margin-right: 0px;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* Privacy policy related doc css */

@page Section1 {
  size: 8.5in 11in;
  margin: 1in;
  mso-header-margin: 0.5in;
  mso-footer-margin: 0.5in;
  mso-paper-source: 0;
}

table {
  border-collapse: collapse;
}

table td,
table th {
  padding: 5px;
}

td {
  page-break-inside: avoid;
}

tr {
  page-break-after: avoid;
}

div.Section1 {
  page: Section1;
}

/* Confluence print stylesheet. Common to all themes for print medi=
a */
/* Full of !important until we improve batching for print CSS */

@media print {
  #main {
    padding-bottom: 1em !important;
    /* The default padding of 6em is to=
o much for printouts */
  }

  body {
    font-family: Arial, Helvetica, FreeSans, sans-serif;
    font-size: 10pt;
    line-height: 1.2;
  }

  body,
  #full-height-container,
  #main,
  #page,
  #content,
  .has-personal-sidebar #content {
    background: var(--ds-surface, #fff) !important;
    color: var(--ds-text, #000) !important;
    border: 0 !important;
    width: 100% !important;
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    display: block !important;
  }

  a,
  a:link,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    color: var(--ds-text, #000);
  }

  #content h1,
  #content h2,
  #content h3,
  #content h4,
  #content h5,
  #content h6 {
    font-family: Arial, Helvetica, FreeSans, sans-serif;
    page-break-after: avoid;
  }

  pre {
    font-family: Monaco, "Courier New", monospace;
  }

  #header,
  .aui-header-inner,
  #navigation,
  #sidebar,
  .sidebar,
  #personal-info-sidebar,
  .ia-fixed-sidebar,
  .page-actions,
  .navmenu,
  .ajs-menu-bar,
  .noprint,
  .inline-control-link,
  .inline-control-link a,
  a.show-labels-editor,
  .global-comment-actions,
  .comment-actions,
  .quick-comment-container,
  #addcomment {
    display: none !important;
  }

  /* CONF-28544 cannot print multiple pages in IE */
  #splitter-content {
    position: relative !important;
  }

  .comment .date::before {
    content: none !important;
    /* remove middot for print view */
  }

  h1.pagetitle img {
    height: auto;
    width: auto;
  }

  .print-only {
    display: block;
  }

  #footer {
    position: relative !important;
    /* CONF-17506 Place the footer at en=
d of the content */
    margin: 0;
    padding: 0;
    background: none;
    clear: both;
  }

  #poweredby {
    border-top: none;
    background: none;
  }

  #poweredby li.print-only {
    display: list-item;
    font-style: italic;
  }

  #poweredby li.noprint {
    display: none;
  }

  /* no width controls in print */
  .wiki-content .table-wrap,
  .wiki-content p,
  .panel .codeContent,
  .panel .codeContent pre,
  .image-wrap {
    overflow: visible !important;
  }

  /* TODO - should this work? */
  #children-section,
  #comments-section .comment,
  #comments-section .comment .comment-body,
  #comments-section .comment .comment-content,
  #comments-section .comment p {
    page-break-inside: avoid;
  }

  #page-children a {
    text-decoration: none;
  }

  /**
hide twixies

the specificity here is a hack because print styles
are getting loaded before the base styles. */
  #comments-section.pageSection .section-header,
  #comments-section.pageSection .section-title,
  #children-section.pageSection .section-header,
  #children-section.pageSection .section-title,
  .children-show-hide {
    padding-left: 0;
    margin-left: 0;
  }

  .children-show-hide.icon {
    display: none;
  }

  /* personal sidebar */
  .has-personal-sidebar #content {
    margin-right: 0px;
  }

  .has-personal-sidebar #content .pageSection {
    margin-right: 0px;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* content section */
header.landing-header {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.common-padding-small {
  padding: 60px 0;
}

.information-pages-section {
  min-height: calc(100vh - 127px);
}

.information-pages-section h1 {
  margin-bottom: 20px;
}

.information-page ul {
  padding: 0 0 0 18px;
}

.job-form-wrap .form-group {
  margin: 0;
}

.job-form-wrap .checkbox-heading-wrap {
  margin-bottom: 15px !important;
}

.form-list {
  margin-bottom: 35px;
  border-bottom: 1px solid var(--dashboard-blue);
  padding-bottom: 15px;
}

@media only screen and (max-width: 991px) {
  .common-padding-small {
    padding: 30px 0;
  }
}

.btn-flex-holder.job-action-btn-full {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.job-form-wrap .custom-chip-inp .MuiTextField-root > .MuiInputBase-root {
  padding-right: 35px !important;
}

.MuiAutocomplete-popper {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border: none !important;
  outline: none !important;
  border-radius: 0 !important;
}
.MuiAutocomplete-popper * {
  font-size: 14px !important;
  font-weight: 600;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.highlight {
  color: #00b428;
  font-weight: 600;
}
.highlightDanger {
  color: red;
  font-weight: 600;
}

.feedback-holder {
  display: flex;
  padding: 18px 15px;
  border: 1px solid #ededed;
  border-radius: 10px;
}

.feedback-holder .job-icon {
  max-width: 60px;
  flex: 0 0 60px;
  width: 60px;
  margin-right: 15px;
}

.feedback-holder .content-holder h5 {
  color: #1a8ac9;
}

.feedback-holder .cm-user h5 {
  font-size: 14px;
  color: #1a8ac9 !important;
}

.feedback-holder .cm-user p {
  color: #aaaaaa;
  font-size: 14px;
  margin: 0;
}

.note-holder {
  margin-top: 20px;
}

.note-holder label {
  margin-bottom: 10px;
}

.note-holder textarea.form-control {
  height: 120px;
  border-radius: 15px !important;
  margin-bottom: 15px;
}
span.hg {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.hg.green {
  color: #00b428;
}
.hg.orange {
  color: #ce9400;
}
.hg.red {
  color: #c50000;
}
.newtabbtn button {
  width: 100% !important;
  min-width: auto !important;
  max-width: 145px;
}
.newtabbtn button:first-child {
  background: #031729;
}
.upcoming button {
  width: 100% !important;
  min-width: auto !important;
  max-width: 215px;
}
/* .upcoming button:first-child {
  background: #031729;
} */
.job-key-info-wrap.increase ul li {
  width: calc(25% - 7px);
}
.job-action-icons.blueicon button svg {
  fill: #002d55;
}
.grey-border {
  border: 1px solid var(--color-grey);
}
.edit-profile-heading h3,
h5 {
  color: var(--dashboard-blue) !important;
}
.payment-dtls-right.max-height {
  height: 490px;
  overflow-y: auto;
}

.pay-table-header-scroller {
  max-height: calc(100vh - 350px);
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 30px;
}
.more-dtls-btn {
  margin-top: 20px;
}
.more-dtls-btn p {
  margin-bottom: 0;
}
.more-dtls-arw {
  margin-left: 2px;
  transform: translateY(-2px);
}

.interviews-card .job-key-info-wrap.increase ul li {
  width: auto !important;
  flex: 0 0 auto !important;
  max-width: none !important;
}
.interviews-card .tech-stack ul {
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
}

.interviews-card .tech-stack ul::after {
  content: "";
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  height: 100%;
  width: 300px;
}

.interviews-card .tech-stack {
  margin-bottom: 0;
}

/*MileStone 5 Fixing*/
.common-modal-style .form-row {
  margin-bottom: 9px;
}

.stripe-form-inner {
  margin-bottom: 40px;
}

.stripe-form-inner {
  margin-bottom: 40px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}

.receipt-head .tbl-status {
  background: var(--blue-mid);
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid var(--blue2);
  color: var(--sub-menu);
  line-height: 1.6;
  font-size: 12px;
  margin-left: 10px;
}

.receipt-head .tbl-status.paid {
  color: #00b912;
  border-color: currentColor;
  background-color: #f4fff2;
}

.receipt-head .tbl-status.cancel {
  color: var(--red);
  border-color: currentColor;
  background-color: #fff2f2;
}

.invoice-back {
  font-size: 12px;
  margin-right: 5px;
}
/*MileStone 5 Fixing*/

.test {
  opacity: 0;
}

.upload-fix-1 {
  transform: translateY(-10px);
}

.dob-fild-fix .has-icon input {
  padding-right: 0;
}

.th-right-wrap-sec.text-center td {
  text-align: center;
}

.th-right-wrap-sec.text-center td .th-table-dtls h4 {
  width: 100%;
}

@media only screen and (max-width: 1799px) {
  .common-card-style {
    padding: 30px;
  }
}

@media only screen and (max-width: 1599px) {
  .common-card-style {
    padding: 25px;
  }

  .mb-40 {
    margin-bottom: 30px;
  }

  .job-details-sidebar {
    padding: 25px;
  }

  .details-is-open .common-card-style {
    width: calc(50% - 10px);
  }

  .details-is-open .job-details-sidebar {
    display: block;
    width: calc(50% - 10px);
  }

  .cus-tab button.MuiTab-root {
    font-size: 16px;
    margin-right: 40px;
  }

  .comp-info ul {
    max-width: 500px;
  }

  .payment-dtls-right {
    padding: 16px;
    border-radius: 15px;
  }

  .job-key-info-wrap ul li {
    width: auto;
    max-width: 25%;
  }
}

/* @media only screen and (max-width:1359px) {
    .job-action-btn-full button {
        display: block;
    }

    .job-action-btn-full button:last-child {
        margin-left: 0 !important;
        margin-top: 10px !important;
    }
} */

@media only screen and (max-width: 1279px) {
  .job-infobox-single {
    padding: 15px;
  }

  .job-key-info-wrap ul {
    flex-wrap: wrap;
  }

  .job-key-info-wrap ul li {
    /* flex: 0 0 calc(50% - 15px); */
    margin-bottom: 10px;
    /* width: calc(25% - 15px); */
  }

  .job-icon {
    margin-right: 10px;
  }

  .job-icon img {
    max-width: 50px;
    height: auto;
  }

  .job-infobox-header {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px 15px 15px;
  }

  .job-action-icons button {
    width: 40px;
    height: 40px;
  }

  .job-short-desc {
    padding-top: 5px;
  }

  .job-action-icons button:first-child {
    margin-right: 5px;
  }

  .job-action-btn-full {
    display: flex;
  }

  .job-list-info ul {
    padding-right: 135px;
  }

  .job-list-info ul span {
    flex: 0 0 135px;
  }

  .job-list-info ul div {
    flex-basis: calc(100% - 135px);
  }

  :is(.usages-section, header, footer) .container {
    max-width: 95% !important;
  }

  .users-tab-new .MuiTabs-flexContainer {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .users-section .MuiTabs-root {
    background-color: transparent !important;
  }

  .users-tab-new .MuiButtonBase-root {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1199px) {
  .contact-section.common-padding {
    padding-bottom: 485px;
  }
}

@media only screen and (max-width: 991px) {
  .common-card-style {
    padding: 15px;
    border-radius: var(--radius-20);
  }

  .job-details-sidebar {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
    transition: 300ms ease-in-out;
    transform: translateY(100%);
  }

  .details-is-open {
    display: block;
  }

  .job-details-sidebar {
    top: 0;
  }

  .details-is-open .common-card-style {
    width: 100%;
  }

  .details-is-open .job-details-sidebar {
    transform: translateY(0%);
    width: 100%;
  }

  .prof-com-info-row {
    flex-direction: column;
  }

  .prof-act-btn-2 {
    padding-top: 20px;
  }

  .invite-dtls-right {
    margin-left: 0;
  }

  .invite-avatar {
    padding-bottom: 90%;
  }

  /*------ Responsive Fix ------*/
  .files-sec-holder h5 {
    font-size: 18px !important;
  }

  .files-sec-holder h6 {
    margin-bottom: 20px;
  }

  .files-blocks-holder {
    margin-bottom: 30px;
  }

  .contact-section.common-padding {
    padding-bottom: 20px;
  }

  img.contect-bg {
    position: static;
    height: auto;
  }

  .acc-sec-from {
    text-align: center;
  }

  .acc-sec-from .form-group {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .acc-sec-from .btn-wraps {
    justify-content: center;
  }

  .user-menu > span {
    display: none;
  }

  .dash-top-nav h1 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .form-btn-holder button {
    margin-bottom: 15px;
    margin-left: 0 !important;
    display: block;
  }

  .addi-icon-wrap {
    flex: 0 0 70px;
    margin-left: 10px;
  }

  .cus-tab button.MuiTab-root {
    font-size: 14px;
    margin-right: 20px;
  }

  .job-action-icons button svg {
    width: 20px;
    height: 20px;
  }

  .job-infobox-header {
    flex-wrap: wrap;
  }

  .job-icon {
    flex: 0 0 50%;
    margin-right: 0;
  }

  .job-action-icons {
    flex: 0 0 100px;
    text-align: right;
  }

  .job-title-wrap {
    order: 2;
    flex: 0 0 100%;
    margin-top: 5px;
  }

  .cus-tab .MuiTabPanel-root {
    padding: 20px 0 0 0 !important;
  }

  .job-provider-info {
    margin-left: 0;
    text-align: left;
    order: 3;
    margin-top: 10px;
  }

  .job-key-info-wrap ul li {
    flex-basis: 100%;
    display: flex;
  }

  .job-key-info-wrap ul li > span {
    padding-right: 10px;
  }

  .job-list-info ul li.salary-li {
    position: static;
  }

  .job-list-info ul {
    padding-right: 0;
    font-size: 14px;
  }

  .tech-stack ul {
    flex-wrap: wrap;
  }

  .tech-stack ul li {
    margin-right: 10px;
  }

  .tech-stack ul li img {
    width: 50px;
    height: auto;
  }

  .res-img-holder img {
    min-height: 110px;
  }

  .user-pic img {
    width: 80px;
    height: 80px;
  }

  .profile-sec-header {
    margin-top: -50px;
  }

  .profile-sec-header {
    flex-direction: column;
  }

  .prof-btn-holder {
    padding-top: 20px;
  }

  .comp-info ul {
    flex-direction: column;
  }

  .prof-btn-holder button:last-child {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .user-info-holder {
    margin-top: 10px;
  }

  .prof-act-btn-2 button:last-child {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .tech-stack.style-2 li {
    margin-bottom: 10px;
  }

  .files-sec-holder > .row > div:last-child {
    margin-top: 20px;
  }

  .profile-tab-cus .MuiTabs-scroller > .MuiTabs-flexContainer {
    overflow: auto;
  }

  .about-post-holder {
    flex-direction: column;
  }

  .post-image-ab {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-post-holder {
    padding-right: 20px;
  }

  .post-image-ab {
    margin-right: 0;
  }

  .about-post-holder {
    padding-left: 0;
    padding-right: 0px;
  }

  .about-post-holder .feed-post-interaction .feed-post-colum {
    margin-right: 10px;
  }

  .about-post-holder .feed-post-menu button.MuiButton-text {
    color: #fff;
    margin-top: 10px !important;
  }

  .prof-btn-holder,
  .prof-act-btn-2 {
    display: flex;
    flex-direction: column;
  }

  .prof-act-btn-2 button,
  .prof-btn-holder button {
    min-width: 100% !important;
  }

  .invite-avatar-list {
    max-width: 200px;
  }

  .invite-dtls-right {
    padding-left: 0;
    margin-top: 20px;
    border: 0;
  }

  .common-modal-style.invite-modal-outer {
    padding-top: 40px !important;
    overflow: auto;
  }

  /*.common-modal-style .common-modal-box {
        top: 40px;
        transform: translate(-50%, 0px);
    }*/

  .invite-modal-outer .modal-cross {
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background-color: var(--primary);
    border-radius: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .write-something-wrap input {
    font-size: 16px;
  }

  .share-profile-modal .common-modal-box {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .files-sec-holder h6 {
    margin-bottom: 0px;
  }

  .users-tab-new {
    text-align: center;
  }

  .users-text p {
    max-width: 100%;
  }

  .users-section .MuiTabs-root {
    margin-left: auto;
    margin-right: auto;
  }

  .users-section .register-btn {
    margin-left: auto !important;
    margin-right: auto;
    display: block;
  }

  .ban-policy {
    text-align: center;
  }

  .usages-Chip .MuiChip-filled {
    font-weight: 600;
    font-size: 12px;
  }

  .usages-Chip .MuiChip-filled .MuiChip-label {
    white-space: pre-wrap;
  }

  .contact-section.common-padding {
    padding-top: 0;
  }

  .job-action-btn-full {
    flex-direction: column;
  }

  .job-infobox-wrap .job-action-btn-full {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .job-action-btn-full button:last-of-type {
    /*margin-top: 10px;*/
    margin-left: 0 !important;
  }

  .job-key-info-wrap ul {
    flex-direction: column;
    row-gap: 5px;
  }

  .MuiBox-root .mb-30 {
    margin-bottom: 0;
  }

  .job-key-info-wrap ul li {
    width: 100%;
    max-width: 100%;
  }

  .job-details-sidebar .job-list-info ul li {
    flex-direction: column;
  }

  .job-details-sidebar .job-list-info ul span {
    flex: 0 0 auto;
  }

  .job-details-sidebar .job-list-info ul div {
    flex: 0 0 auto;
  }

  .job-list-info ul .list-work-wrap span.list-work {
    flex: 0 0 auto;
    padding-left: 10px;
  }

  .job-list-info ul .list-work-wrap span.list-work:first-child {
    padding-left: 0;
  }

  .job-details-sidebar .job-list-info ul li.salary-li {
    display: flex;
  }

  .job-list-info ul li.salary-li div {
    padding: 0;
  }

  .job-form-wrap .form-list {
    margin-bottom: 20px;
  }

  .job-form-btns {
    margin-top: 20px;
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .job-form-btns button {
    width: 100% !important;
  }
  .job-listing-item .job-listing-item-img {
    display: flex;
    align-items: center;
  }

  .job-listing-item .job-listing-item-img .MuiAvatar-root {
    width: 40px;
    height: 40px;
  }

  .job-listing-item label {
    margin-right: 5px;
  }
  .rev-cand-row .job-listing-hidden .job-listing-info ul {
    max-width: 100%;
    margin-left: 0;
    padding-top: 10px;
    row-gap: 10px;
  }
  .rev-cand-row .job-listing-hidden .job-listing-info ul li {
    width: 100%;
    flex-basis: 100%;
    padding-bottom: 8px;
    margin-bottom: 9px;
    border-bottom: 1px solid #eaeaea;
  }
  .job-form-btns {
    margin-top: 15px !important;
  }
  .rev-cand-row ul.job-tech-stack li {
    width: 31.3%;
    background: #eaeaea;
    padding: 9px 5px;
  }

  .rev-cand-row ul.job-tech-stack li p {
    font-size: 10px !important;
    margin-top: 7px;
  }
  .job-publish-btn button {
    width: 100% !important;
  }
  .job-listing-icons {
    margin: 15px 0;
  }
}

:root {
  --main-font: "Inter", sans-serif;
  --primary: #031729;
  --dashboard-blue: #002d55;
  --secondary: #1a8ac9;
  --black: #000000;
  --white: #ffffff;
  --red: #c50000;
  --red-n: #ff0000;
  --sub-menu: #0b5280;
  --oreng-dark: #ff5c00;
  --link: #0066ff;
  --green-th: #00b428;
  --yellow-th: #ce9400;

  --form-input: #f8f8f8;
  --border-color: #eeeeee;
  --border-color2: #e8e8e8;
  --gray-light: #bbbbbb;
  --gray-light2: #fafafa;
  --gray-mid: #777777;
  --gray-mid2: #cccccc;
  --gray-dark: #414141;
  --gray-dark2: #444444;
  --body-text-color: #555555;
  --swith: #aeaeae;

  --gren: #edffed;
  --oreng: #ffebec;
  --parpel: #efeafd;
  --blue: #eafbff;
  --blue2: #e1f4ff;
  --blue-mid: #f2faff;
  --aud-color: #f5fcf8;
  --use-bg: #fffcf8;
  --dash-bg: #f5f7fb;

  --light: 200;
  --regular: 200;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;

  --radius-10: 10px;
  --radius-20: 20px;
  --radius-30: 30px;
  --radius-50: 50px;
}

.landing-contact {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.pointer {
  cursor: pointer !important;
}

.form-group .show-pass {
  top: 21px;
}

.form-group input {
  border-radius: 18px !important;
}

.banner-form .form-control {
  border-radius: 18px;
}

.form-group label {
  padding-left: 0;
  z-index: 1 !important;
}

span.btn {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  padding: 12px 45px;
  font-size: 16px;
  font-weight: var(--semi-bold);
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}

span.foot-btn {
  background: transparent;
  border: 1px solid var(--white);
  text-decoration: none;
  border-radius: var(--radius-50);
  color: var(--white);
  padding: 18px 45px;
  font-size: 18px;
  font-weight: var(--semi-bold);
  margin: 0 0 0 30px;
}

.foot-btn.white {
  background: var(--white);
  color: var(--primery);
}

.ftr-btm-link ul span {
  color: var(--white);
  text-decoration: none;
  font-size: 14px;
}

label.remember-wrap span {
  color: var(--secondary);
  display: inline-block;
  text-decoration: inherit;
}

.ban-join-now {
  display: contents;
  cursor: pointer;
}

.register-btn {
  background: var(--primary);
  border-radius: var(--radius-50) !important;
  display: block;
  color: var(--white) !important;
  padding: 12px 45px !important;
  font-size: 16px !important;
  font-weight: var(--semi-bold) !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  text-align: center !important;
  margin-left: 26px !important;
}

.resend-otp {
  color: #082b53;
  text-decoration: underline;
  margin-bottom: 10px;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-error-message {
  color: #d32f2f !important;
  text-align: left !important;
  margin-bottom: 0px;
  font-size: 13px !important;
  margin: 5px 0 0 13px;
}

.asteric-error {
  color: #d32f2f !important;
}

.form-warning-message {
  color: green !important;
  text-align: left !important;
  margin-bottom: 0px;
  font-size: 14px !important;
}

.para {
  color: var(--secondary);
  padding: 0;
  font-weight: 700;
  background: transparent !important;
  text-decoration: underline;
}

.users-tab {
  flex-flow: column;
}

.users-tab span.btn {
  background: #d4ebff;
  color: #a0c3e2;
}

.users-tab span.btn.active {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  border-color: var(--primary);
}

@media only screen and (max-width: 1199px) {
  .dashboard-sidenav-active {
    left: 10px;
    z-index: 3;
  }
}

.users-section .Mui-selected {
  background: var(--dashboard-blue) !important;
  border-radius: 30px;
  color: white !important;
}

.users-section .MuiTabs-root {
  background: var(--form-input);
  border-radius: 30px;
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.users-section .MuiTabs-indicator {
  background: transparent;
}

.users-tab-new .MuiTabs-flexContainer {
  gap: 5px;
}

.users-tab-new .MuiButtonBase-root {
  background: #d4ebff;
  color: #a0c3e2;
  border-radius: 30px;
}

.active-scroll-div:after {
  content: "";
  background: url(/assets/blue-arrow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: calc(50% - 5px);
  width: 8px;
  height: 12px;
  position: absolute;
  right: 0;
  opacity: 0;
}

.active-scroll-div:after {
  opacity: 1 !important;
}

.active-scroll-div p {
  color: var(--secondary) !important;
}

.src-box {
  margin-left: auto;
}

.save-button {
  background: transparent;
  color: var(--secondary);
  font-weight: 700;
  /* text-decoration: underline; */
}

/* .checkbox-heading {
  margin: 15px !important;
} */

/* .file-preview-block {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-flow: column;
  border: none !important;
} */

.file-preview-block .files-image-holder {
  width: 40px !important;
  height: 40px !important;
}

.resume-list img {
  width: 40px !important;
}

.resume-list .previewImage {
  margin: auto;
  width: auto;
}

.resume-list .files-image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverLetter-list img {
  width: 40px !important;
}

.coverLetter-list .previewImage {
  margin: auto;
  width: auto;
}

.coverLetter-list .files-image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-label {
  border-radius: 10px;
}

button.MuiButtonBase-root {
  z-index: 1;
}

.single-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

.loader {
  width: 100px;
  height: 100px;
  animation: squeezeAndBlink 2.5s infinite;
  background-color: #ffffff8c;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes squeezeAndBlink {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.7);
    opacity: 0.5;
  }
}

.advanced-cropper {
  height: 50vh;
  margin: 20px 0;
}

.example__cropper-background {
  /* height: 50vh !important; */
  object-fit: contain;
}

.profile-view-banner {
  position: relative;
  height: 40vh;
}

.profile-sec-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.dynamic-error {
  margin: 0 0 20px 5px;
}

.download-file {
  font-size: 13px;
  text-decoration: underline;
}

.customLink {
  font-size: 13px;
  text-decoration: underline;
  color: var(--secondary);
}

.custom-skills {
  margin-top: 20px !important;
}

/* No content Componet */
.no-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  font-size: 2rem;
  /* Large font size */
  font-weight: bold;
  /* Bold text */
  text-align: center;
  color: #333;
  /* Optional: Customize text color */
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-menu span {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.user-info-holder h4 {
  /* max-width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-text .fz-25 {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-text p {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tech-stack ul li p {
  min-width: 40px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiFormControlLabel-asterisk {
  color: #d32f2f;
}

.adorment p.MuiTypography-root.MuiTypography-body1 {
  color: var(--secondary);
}

.adorment #profileUrl {
  padding-left: 0px;
}
.job-share-social-pannel {
  align-items: center;
}

.job-share-social-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  gap: 10px;
}

.selectedList {
  background: var(--sub-menu);
  color: var(--white) !important;
  border-radius: var(--radius-10);
}

.sideNavSelected {
  background-color: white !important;
  color: var(--dashboard-blue) !important;
}

.sideNavSelected .nav-arrow {
  color: var(--dashboard-blue) !important;
}

.m-0 {
  margin: 0 !important;
}

.base-Popper-root.MuiPickersPopper-root {
  z-index: 1;
}

/* For Ck editor error */
.ckeditor-text-field {
  margin-bottom: 1em;
}

.ckeditor-label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.required-asterisk {
  color: red;
  margin-left: 0.25em;
}

.ckeditor-error {
  color: red;
  margin-top: 0.5em;
  font-size: 0.875em;
}

.disable_button {
  background-color: #e0e0e0 !important;
}

.cus-slider-width {
  width: 100% !important;
}

.add-question {
  float: right;
  margin-right: 0;
  margin-top: 20px;
}

.single-job-card {
  margin: 20px 0;
}

.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
  padding-right: 9px !important;
}

.current-role-viwer {
  font-size: 12px;
  font-weight: 400;
}

.ck.ck-editor__editable_inline {
  border-radius: 0 0px 13px 13px !important;
}

.ck.ck-toolbar.ck-toolbar_grouping {
  border-radius: 13px 13px 0px 0px !important;
}

.ck.ck-sticky-panel__content {
  border-radius: 13px 13px 0px 0px !important;
}

.interviwer-address-view ul li p {
  white-space: normal !important;
}

.skills-items-main {
  justify-content: flex-start !important;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
  font-size: 13px;
  margin: 0 0 0 12px;
}

.valid:before {
  position: relative;
  left: -5px;
  content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: #d32f2f;
  font-size: 13px;
  margin: 0 0 0 12px;
}

.invalid:before {
  position: relative;
  left: -5px;
  content: "✖";
}

.password-matcher {
  margin-top: -15px;
  margin-bottom: 10px;
}

.company-profile-role-filter {
  display: flex;
  align-items: center;
  gap: 20px;
}

.company-profile-role-filter .single-filter {
  width: 200px;
}

@media only screen and (max-width: 700px) {
  .company-profile-role-filter .single-filter {
    width: 100%;
    margin-top: 10px;
  }

  .company-profile-role-filter {
    display: block;
  }
}

.job-apply-resume-card {
  display: flex;
  gap: 10px;
}

.job-apply-resume-card > div {
  min-width: 50%;
}

.job-skeleton {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
}

.job-skeleton .skel-card-job {
  width: 48% !important;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 1px 10px 30px rgba(0, 0, 0, 0.03);
}

.skel-card-job .skel-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skel-card-job .skel-icon span {
  height: 80px;
  width: 50px;
  border-radius: 50%;
}

.skel-card-job .skel-title {
  height: 20px;
  width: 90%;
}

.skel-card-job .skel-desc {
  height: 20px;
}

.skel-card-job .skel-desc {
  height: 50px;
}

.list-work-wrap .list-work:first-child::after {
  display: none;
}

.close-checkbox {
  justify-content: flex-start;
}

.close-job-radio {
  flex-flow: row !important;
}

.MuiFormLabel-asterisk {
  color: var(--red);
}

.candidate-skel {
  border: 1px solid var(--blue2);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.candidate-skel .upper-portion {
  display: flex;
  align-items: center;
  gap: 10px;
}

.candidate-skel .upper-portion .description {
  width: 100%;
}

.candidate-skel .upper-portion .profile-pic {
  border-radius: 50%;
}

.candidate-skel .long-desc {
  display: flex;
  gap: 10px;
}

.candidate-skel .long-desc span {
  width: 100%;
}

/* Js custom scroll bar implement */

/* width */
::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
  height: 4px;
}

/* Track */
/* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dashboard-blue);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dashboard-blue);
}

/* Payment */
/* CheckoutForm.css */
/* .custom-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .custom-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .custom-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .custom-button {
    background-color: #6772e5;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  .custom-button:disabled {
    background-color: #ccc;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .success {
    color: green;
    margin-top: 10px;
  } */
.about-paragraph {
  color: var(--gray-dark2);
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 30px;
  word-wrap: break-word;
  word-break: break-word;
  position: relative;
}

.about-paragraph.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(1.4em * 2);
  /* Restricts to two lines */
}

.about-paragraph.full-text {
  display: block;
  max-height: none;
  /* No limit when expanded */
  overflow: visible;
  /* Reset overflow */
}

.toggle-button {
  background: none;
  border: none;
  color: var(--primary-color);
  /* Customize the color */
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
}

.saved-card-list {
  width: 100%;
}

._success {
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success .tick {
  background-color: #a9ffa9;
  border-radius: 50%;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 30px !important;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

.image-icon-card {
  max-width: 50px;
  height: auto;
}

.card-number-stripe {
  display: flex;
  align-items: center;
  gap: 10px;
}

#card-number {
  width: 100%;
}

.form-label-stripe {
  font-size: 0.85rem;
  color: #30313d;
  margin-bottom: 5px;
}

.actions-section {
  display: flex;
  align-items: center;
  gap: 15px;
}
/* In your global CSS file */
.MuiPickersPopper-root {
  z-index: 1500 !important;
}
.pac-container {
  z-index: 2000 !important; /* Ensure it's above the modal */
}

/* disable scroll during drag */

.disable-scroll {
  overflow: hidden;
}
.file-message {
  /* height: 100px; */
  border: 1px solid;
  border-radius: 20px;
}
.file-message-card {
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  position: relative;
}
.cross-file {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.modal-container {
  position: relative;
  width: 80%;
  max-width: 800px;
  height: 80%;
  margin: auto;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.modal-header {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  z-index: 2;
}

.modal-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-image {
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.unarchived-section * {
  margin: 0;
}

.unarchived-section p {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  line-height: 1;
}

.unarchived-section p svg {
  font-size: 32px;
}

/* .unarchived-section {
  margin: 15px 0 10px;
  border-radius: 10px;
  padding: 15px 16px;
} */

.unarchived-section p {
  gap: 8px;
}

.unarchived-section p svg {
  font-size: 22px;
}
/* .unarchived-section {
  padding: 15px 12px;
} */

.chat-contact-list.active {
  background-color: var(--blue2);
}
.chat-skel-right {
  width: 40%;
  height: 80px !important;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 35px 50px 0px 35px !important;
}
.chat-skel-left {
  width: 40%;
  height: 80px !important;
  margin-right: auto;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 0px 35px 35px 50px !important;
}
.chat-room-skel {
  width: 100%;
  height: 80px !important;
}

.video-overlapper {
  color: transparent;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-color: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-overlapper img {
  border-radius: 50%;
}
.feedback-slider .Mui-disabled {
  color: #1976d2 !important;
  pointer-events: auto;
}
.feedback-slider .MuiSlider-valueLabelOpen {
  background: #1976d2 !important;
}
.disable-scroll-chat {
  padding-bottom: 0px;
  overflow: hidden;
}

.container-skeleton {
  max-width: inherit;
  margin: 2rem auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
}

.skeleton-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
}

.skeleton-card:last-child {
  border-bottom: none;
}

.skeleton-profile {
  display: flex;
  align-items: center;
}

.skeleton-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 10px;
}

.skeleton-info {
  display: flex;
  flex-direction: column;
}

.skeleton-line {
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 6px;
  width: 100%;
}

.skeleton-line.short {
  width: 60%;
}

.skeleton-button {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.dots-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dots-loader span {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  animation: dot-blink 1.5s infinite;
}

.dots-loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots-loader span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%,
  80%,
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
}

.loader-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.tbl-status.pending {
  color: #ce9400;
}
.dltplus_wrap .archived-section.pointer {
  flex-grow: 1;
  width: auto;
  border-radius: 30px !important;
}

.transaction-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.transaction-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.table-wrapper {
  overflow-x: auto;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  background: #f5f5f5;
  font-weight: bold;
}

.transaction-table tr:nth-child(even) {
  background: #f9f9f9;
}

.status.success {
  color: green;
  font-weight: bold;
}

.details-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.details-button:hover {
  background: #0056b3;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: 0.2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}
.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: env(safe-area-inset-left);
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}
.Toastify__toast {
  --y: 0;
  position: relative;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
  overflow: hidden;
}
.Toastify__toast--stacked {
  position: absolute;
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform 0.3s;
}
.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity 0.1s;
}
.Toastify__toast--stacked[data-collapsed=false] {
  overflow: visible;
}
.Toastify__toast--stacked[data-collapsed=true]:not(:last-child) > * {
  opacity: 0;
}
.Toastify__toast--stacked:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--g) * 1px);
  bottom: 100%;
}
.Toastify__toast--stacked[data-pos=top] {
  top: 0;
}
.Toastify__toast--stacked[data-pos=bot] {
  bottom: 0;
}
.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
  transform-origin: top;
}
.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
  transform-origin: bottom;
}
.Toastify__toast--stacked:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: scaleY(3);
  z-index: -1;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  z-index: 1;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp[data-hidden=true] {
  opacity: 0;
}
.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }
  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
