:root {
  --main-font: "Inter", sans-serif;
  --primary: #031729;
  --dashboard-blue: #002d55;
  --secondary: #1a8ac9;
  --black: #000000;
  --white: #ffffff;
  --red: #c50000;
  --red-n: #ff0000;
  --sub-menu: #0b5280;
  --oreng-dark: #ff5c00;
  --link: #0066ff;
  --green-th: #00b428;
  --yellow-th: #ce9400;

  --form-input: #f8f8f8;
  --border-color: #eeeeee;
  --border-color2: #e8e8e8;
  --gray-light: #bbbbbb;
  --gray-light2: #fafafa;
  --gray-mid: #777777;
  --gray-mid2: #cccccc;
  --gray-dark: #414141;
  --gray-dark2: #444444;
  --body-text-color: #555555;
  --swith: #aeaeae;

  --gren: #edffed;
  --oreng: #ffebec;
  --parpel: #efeafd;
  --blue: #eafbff;
  --blue2: #e1f4ff;
  --blue-mid: #f2faff;
  --aud-color: #f5fcf8;
  --use-bg: #fffcf8;
  --dash-bg: #f5f7fb;

  --light: 200;
  --regular: 200;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;

  --radius-10: 10px;
  --radius-20: 20px;
  --radius-30: 30px;
  --radius-50: 50px;
}

.landing-contact {
  width: fit-content !important;
}

.pointer {
  cursor: pointer !important;
}

.form-group .show-pass {
  top: 21px;
}

.form-group input {
  border-radius: 18px !important;
}

.banner-form .form-control {
  border-radius: 18px;
}

.form-group label {
  padding-left: 0;
  z-index: 1 !important;
}

span.btn {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  padding: 12px 45px;
  font-size: 16px;
  font-weight: var(--semi-bold);
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}

span.foot-btn {
  background: transparent;
  border: 1px solid var(--white);
  text-decoration: none;
  border-radius: var(--radius-50);
  color: var(--white);
  padding: 18px 45px;
  font-size: 18px;
  font-weight: var(--semi-bold);
  margin: 0 0 0 30px;
}

.foot-btn.white {
  background: var(--white);
  color: var(--primery);
}

.ftr-btm-link ul span {
  color: var(--white);
  text-decoration: none;
  font-size: 14px;
}

label.remember-wrap span {
  color: var(--secondary);
  display: inline-block;
  text-decoration: inherit;
}

.ban-join-now {
  display: contents;
  cursor: pointer;
}

.register-btn {
  background: var(--primary);
  border-radius: var(--radius-50) !important;
  display: block;
  color: var(--white) !important;
  padding: 12px 45px !important;
  font-size: 16px !important;
  font-weight: var(--semi-bold) !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  text-align: center !important;
  margin-left: 26px !important;
}

.resend-otp {
  color: #082b53;
  text-decoration: underline;
  margin-bottom: 10px;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-error-message {
  color: #d32f2f !important;
  text-align: left !important;
  margin-bottom: 0px;
  font-size: 13px !important;
  margin: 5px 0 0 13px;
}

.asteric-error {
  color: #d32f2f !important;
}

.form-warning-message {
  color: green !important;
  text-align: left !important;
  margin-bottom: 0px;
  font-size: 14px !important;
}

.para {
  color: var(--secondary);
  padding: 0;
  font-weight: 700;
  background: transparent !important;
  text-decoration: underline;
}

.users-tab {
  flex-flow: column;
}

.users-tab span.btn {
  background: #d4ebff;
  color: #a0c3e2;
}

.users-tab span.btn.active {
  background: var(--primary);
  border-radius: var(--radius-50);
  display: block;
  color: var(--white);
  border-color: var(--primary);
}

@media only screen and (max-width: 1199px) {
  .dashboard-sidenav-active {
    left: 10px;
    z-index: 3;
  }
}

.users-section .Mui-selected {
  background: var(--dashboard-blue) !important;
  border-radius: 30px;
  color: white !important;
}

.users-section .MuiTabs-root {
  background: var(--form-input);
  border-radius: 30px;
  padding: 5px;
  width: fit-content;
}

.users-section .MuiTabs-indicator {
  background: transparent;
}

.users-tab-new .MuiTabs-flexContainer {
  gap: 5px;
}

.users-tab-new .MuiButtonBase-root {
  background: #d4ebff;
  color: #a0c3e2;
  border-radius: 30px;
}

.active-scroll-div:after {
  content: "";
  background: url(/assets/blue-arrow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: calc(50% - 5px);
  width: 8px;
  height: 12px;
  position: absolute;
  right: 0;
  opacity: 0;
}

.active-scroll-div:after {
  opacity: 1 !important;
}

.active-scroll-div p {
  color: var(--secondary) !important;
}

.src-box {
  margin-left: auto;
}

.save-button {
  background: transparent;
  color: var(--secondary);
  font-weight: 700;
  /* text-decoration: underline; */
}

/* .checkbox-heading {
  margin: 15px !important;
} */

/* .file-preview-block {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-flow: column;
  border: none !important;
} */

.file-preview-block .files-image-holder {
  width: 40px !important;
  height: 40px !important;
}

.resume-list img {
  width: 40px !important;
}

.resume-list .previewImage {
  margin: auto;
  width: auto;
}

.resume-list .files-image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverLetter-list img {
  width: 40px !important;
}

.coverLetter-list .previewImage {
  margin: auto;
  width: auto;
}

.coverLetter-list .files-image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-label {
  border-radius: 10px;
}

button.MuiButtonBase-root {
  z-index: 1;
}

.single-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

.loader {
  width: 100px;
  height: 100px;
  animation: squeezeAndBlink 2.5s infinite;
  background-color: #ffffff8c;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes squeezeAndBlink {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.7);
    opacity: 0.5;
  }
}

.advanced-cropper {
  height: 50vh;
  margin: 20px 0;
}

.example__cropper-background {
  /* height: 50vh !important; */
  object-fit: contain;
}

.profile-view-banner {
  position: relative;
  height: 40vh;
}

.profile-sec-header {
  position: sticky;
  z-index: 1;
}

.dynamic-error {
  margin: 0 0 20px 5px;
}

.download-file {
  font-size: 13px;
  text-decoration: underline;
}

.customLink {
  font-size: 13px;
  text-decoration: underline;
  color: var(--secondary);
}

.custom-skills {
  margin-top: 20px !important;
}

/* No content Componet */
.no-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  font-size: 2rem;
  /* Large font size */
  font-weight: bold;
  /* Bold text */
  text-align: center;
  color: #333;
  /* Optional: Customize text color */
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-menu span {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.user-info-holder h4 {
  /* max-width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-text .fz-25 {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-text p {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tech-stack ul li p {
  min-width: 40px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiFormControlLabel-asterisk {
  color: #d32f2f;
}

.adorment p.MuiTypography-root.MuiTypography-body1 {
  color: var(--secondary);
}

.adorment #profileUrl {
  padding-left: 0px;
}
.job-share-social-pannel {
  align-items: center;
}

.job-share-social-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  gap: 10px;
}

.selectedList {
  background: var(--sub-menu);
  color: var(--white) !important;
  border-radius: var(--radius-10);
}

.sideNavSelected {
  background-color: white !important;
  color: var(--dashboard-blue) !important;
}

.sideNavSelected .nav-arrow {
  color: var(--dashboard-blue) !important;
}

.m-0 {
  margin: 0 !important;
}

.base-Popper-root.MuiPickersPopper-root {
  z-index: 1;
}

/* For Ck editor error */
.ckeditor-text-field {
  margin-bottom: 1em;
}

.ckeditor-label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.required-asterisk {
  color: red;
  margin-left: 0.25em;
}

.ckeditor-error {
  color: red;
  margin-top: 0.5em;
  font-size: 0.875em;
}

.disable_button {
  background-color: #e0e0e0 !important;
}

.cus-slider-width {
  width: 100% !important;
}

.add-question {
  float: right;
  margin-right: 0;
  margin-top: 20px;
}

.single-job-card {
  margin: 20px 0;
}

.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
  padding-right: 9px !important;
}

.current-role-viwer {
  font-size: 12px;
  font-weight: 400;
}

.ck.ck-editor__editable_inline {
  border-radius: 0 0px 13px 13px !important;
}

.ck.ck-toolbar.ck-toolbar_grouping {
  border-radius: 13px 13px 0px 0px !important;
}

.ck.ck-sticky-panel__content {
  border-radius: 13px 13px 0px 0px !important;
}

.interviwer-address-view ul li p {
  white-space: normal !important;
}

.skills-items-main {
  justify-content: flex-start !important;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
  font-size: 13px;
  margin: 0 0 0 12px;
}

.valid:before {
  position: relative;
  left: -5px;
  content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: #d32f2f;
  font-size: 13px;
  margin: 0 0 0 12px;
}

.invalid:before {
  position: relative;
  left: -5px;
  content: "✖";
}

.password-matcher {
  margin-top: -15px;
  margin-bottom: 10px;
}

.company-profile-role-filter {
  display: flex;
  align-items: center;
  gap: 20px;
}

.company-profile-role-filter .single-filter {
  width: 200px;
}

@media only screen and (max-width: 700px) {
  .company-profile-role-filter .single-filter {
    width: 100%;
    margin-top: 10px;
  }

  .company-profile-role-filter {
    display: block;
  }
}

.job-apply-resume-card {
  display: flex;
  gap: 10px;
}

.job-apply-resume-card > div {
  min-width: 50%;
}

.job-skeleton {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
}

.job-skeleton .skel-card-job {
  width: 48% !important;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 1px 10px 30px rgba(0, 0, 0, 0.03);
}

.skel-card-job .skel-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skel-card-job .skel-icon span {
  height: 80px;
  width: 50px;
  border-radius: 50%;
}

.skel-card-job .skel-title {
  height: 20px;
  width: 90%;
}

.skel-card-job .skel-desc {
  height: 20px;
}

.skel-card-job .skel-desc {
  height: 50px;
}

.list-work-wrap .list-work:first-child::after {
  display: none;
}

.close-checkbox {
  justify-content: flex-start;
}

.close-job-radio {
  flex-flow: row !important;
}

.MuiFormLabel-asterisk {
  color: var(--red);
}

.candidate-skel {
  border: 1px solid var(--blue2);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.candidate-skel .upper-portion {
  display: flex;
  align-items: center;
  gap: 10px;
}

.candidate-skel .upper-portion .description {
  width: 100%;
}

.candidate-skel .upper-portion .profile-pic {
  border-radius: 50%;
}

.candidate-skel .long-desc {
  display: flex;
  gap: 10px;
}

.candidate-skel .long-desc span {
  width: 100%;
}

/* Js custom scroll bar implement */

/* width */
::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
  height: 4px;
}

/* Track */
/* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dashboard-blue);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dashboard-blue);
}

/* Payment */
/* CheckoutForm.css */
/* .custom-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .custom-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .custom-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .custom-button {
    background-color: #6772e5;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  .custom-button:disabled {
    background-color: #ccc;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .success {
    color: green;
    margin-top: 10px;
  } */
.about-paragraph {
  color: var(--gray-dark2);
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 30px;
  word-wrap: break-word;
  word-break: break-word;
  position: relative;
}

.about-paragraph.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(1.4em * 2);
  /* Restricts to two lines */
}

.about-paragraph.full-text {
  display: block;
  max-height: none;
  /* No limit when expanded */
  overflow: visible;
  /* Reset overflow */
}

.toggle-button {
  background: none;
  border: none;
  color: var(--primary-color);
  /* Customize the color */
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
}

.saved-card-list {
  width: 100%;
}

._success {
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success .tick {
  background-color: #a9ffa9;
  border-radius: 50%;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 30px !important;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

.image-icon-card {
  max-width: 50px;
  height: auto;
}

.card-number-stripe {
  display: flex;
  align-items: center;
  gap: 10px;
}

#card-number {
  width: 100%;
}

.form-label-stripe {
  font-size: 0.85rem;
  color: #30313d;
  margin-bottom: 5px;
}

.actions-section {
  display: flex;
  align-items: center;
  gap: 15px;
}
/* In your global CSS file */
.MuiPickersPopper-root {
  z-index: 1500 !important;
}
.pac-container {
  z-index: 2000 !important; /* Ensure it's above the modal */
}

/* disable scroll during drag */

.disable-scroll {
  overflow: hidden;
}
.file-message {
  /* height: 100px; */
  border: 1px solid;
  border-radius: 20px;
}
.file-message-card {
  width: fit-content;
  padding: 10px;
  position: relative;
}
.cross-file {
  position: absolute;
  width: fit-content;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.modal-container {
  position: relative;
  width: 80%;
  max-width: 800px;
  height: 80%;
  margin: auto;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.modal-header {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  z-index: 2;
}

.modal-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-image {
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.unarchived-section * {
  margin: 0;
}

.unarchived-section p {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  line-height: 1;
}

.unarchived-section p svg {
  font-size: 32px;
}

/* .unarchived-section {
  margin: 15px 0 10px;
  border-radius: 10px;
  padding: 15px 16px;
} */

.unarchived-section p {
  gap: 8px;
}

.unarchived-section p svg {
  font-size: 22px;
}
/* .unarchived-section {
  padding: 15px 12px;
} */

.chat-contact-list.active {
  background-color: var(--blue2);
}
.chat-skel-right {
  width: 40%;
  height: 80px !important;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 35px 50px 0px 35px !important;
}
.chat-skel-left {
  width: 40%;
  height: 80px !important;
  margin-right: auto;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 0px 35px 35px 50px !important;
}
.chat-room-skel {
  width: 100%;
  height: 80px !important;
}

.video-overlapper {
  color: transparent;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-color: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-overlapper img {
  border-radius: 50%;
}
.feedback-slider .Mui-disabled {
  color: #1976d2 !important;
  pointer-events: auto;
}
.feedback-slider .MuiSlider-valueLabelOpen {
  background: #1976d2 !important;
}
.disable-scroll-chat {
  padding-bottom: 0px;
  overflow: hidden;
}

.container-skeleton {
  max-width: inherit;
  margin: 2rem auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
}

.skeleton-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
}

.skeleton-card:last-child {
  border-bottom: none;
}

.skeleton-profile {
  display: flex;
  align-items: center;
}

.skeleton-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 10px;
}

.skeleton-info {
  display: flex;
  flex-direction: column;
}

.skeleton-line {
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 6px;
  width: 100%;
}

.skeleton-line.short {
  width: 60%;
}

.skeleton-button {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.dots-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dots-loader span {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  animation: dot-blink 1.5s infinite;
}

.dots-loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots-loader span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%,
  80%,
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
}

.loader-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.tbl-status.pending {
  color: #ce9400;
}
.dltplus_wrap .archived-section.pointer {
  flex-grow: 1;
  width: auto;
  border-radius: 30px !important;
}

.transaction-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.transaction-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.table-wrapper {
  overflow-x: auto;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  background: #f5f5f5;
  font-weight: bold;
}

.transaction-table tr:nth-child(even) {
  background: #f9f9f9;
}

.status.success {
  color: green;
  font-weight: bold;
}

.details-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.details-button:hover {
  background: #0056b3;
}
